import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Available24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M11.58 13.57C14.66 9.44002 17.44 6.18002 20.58 2.48002C21.5 1.48002 22.33 2.30002 21.89 3.00002C21.45 3.70002 16 13.1 13.16 17.13C13.0317 17.3721 12.8444 17.5779 12.6155 17.7284C12.3866 17.879 12.1234 17.9694 11.8503 17.9913C11.5772 18.0132 11.303 17.966 11.053 17.8539C10.803 17.7418 10.5853 17.5685 10.42 17.35C9.41999 16.21 6.67999 13.02 6.32999 12.55C6.0653 12.216 5.94009 11.7926 5.98059 11.3684C6.02108 10.9441 6.22415 10.552 6.54725 10.2742C6.87036 9.99629 7.28844 9.85421 7.71395 9.87767C8.13946 9.90113 8.53939 10.0883 8.82999 10.4C9.40999 11 11.22 13.13 11.58 13.57Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
