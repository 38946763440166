import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const CcSelected24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip-cc-selected-24)">
        <path
          d="M5.43002 10H22.49C22.6167 9.99718 22.7395 10.0435 22.8327 10.1293C22.9259 10.215 22.9823 10.3336 22.99 10.46L23.92 23.46C23.9255 23.5289 23.9167 23.5983 23.8941 23.6636C23.8714 23.729 23.8355 23.789 23.7885 23.8397C23.7415 23.8905 23.6845 23.9309 23.6211 23.9585C23.5576 23.9861 23.4892 24.0002 23.42 24H4.50002C4.43085 24.0002 4.36239 23.9861 4.29896 23.9585C4.23554 23.9309 4.17853 23.8905 4.13153 23.8397C4.08454 23.789 4.04859 23.729 4.02595 23.6636C4.00331 23.5983 3.99448 23.5289 4.00002 23.46L4.93002 10.46C4.93774 10.3336 4.99413 10.215 5.08735 10.1293C5.18057 10.0435 5.30338 9.99718 5.43002 10Z"
          fill="#7FD6EC"
        />
      </g>
      <path
        d="M3 8H21L22 22H2L3 8Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8 10V6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2V2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6V10"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip-cc-selected-24">
          <rect width="19.93" height="14" fill="white" transform="translate(4 10)" />
        </clipPath>
      </defs>
    </BaseSVGIcon>
  );
};

export const Cc24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M3 8h18l1 14H2L3 8Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8 10V6a4 4 0 0 1 4-4v0a4 4 0 0 1 4 4v4"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
