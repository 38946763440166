import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Shop24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M19 12V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74001 12C5.33599 12.0062 4.93558 11.9231 4.56748 11.7564C4.19938 11.5898 3.87269 11.3437 3.61083 11.036C3.34896 10.7283 3.15841 10.3664 3.0528 9.97639C2.9472 9.58636 2.92918 9.17781 3.00001 8.77999L3.39001 5.77999C3.45887 5.27653 3.71139 4.81628 4.09903 4.48771C4.48666 4.15914 4.98206 3.98544 5.49001 3.99999H9.22001L9.00001 8.77999C8.96032 9.62805 8.60295 10.4301 7.99893 11.0267C7.39491 11.6233 6.58849 11.9708 5.74001 12V12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12C11.5868 12.0011 11.1779 11.9169 10.7988 11.7525C10.4197 11.5882 10.0786 11.3473 9.79697 11.045C9.51531 10.7427 9.29914 10.3855 9.162 9.99572C9.02487 9.60596 8.96972 9.19207 9.00002 8.78L9.22002 4H14.78L15 8.78C15.0303 9.19207 14.9752 9.60596 14.838 9.99572C14.7009 10.3855 14.4847 10.7427 14.2031 11.045C13.9214 11.3473 13.5804 11.5882 13.2013 11.7525C12.8222 11.9169 12.4132 12.0011 12 12V12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.26 12C17.4115 11.9708 16.6051 11.6234 16.0011 11.0268C15.3971 10.4302 15.0397 9.62808 15 8.78003L14.78 4.00003H18.51C19.0084 3.99194 19.4928 4.16482 19.8734 4.4866C20.254 4.80839 20.5051 5.25727 20.58 5.75003L20.97 8.75003C21.0453 9.14751 21.0317 9.55673 20.9301 9.94832C20.8285 10.3399 20.6414 10.7041 20.3823 11.0148C20.1232 11.3255 19.7986 11.575 19.4316 11.7454C19.0647 11.9157 18.6646 12.0027 18.26 12V12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
