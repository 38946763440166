import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Wool32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M13 28C20 29 33.76 25.74 29 11C26 1.79999 31 3.99999 31 3.99999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.16 16.8C15.1476 18.6504 12.5637 19.7581 9.836 19.94C7.10827 20.1218 4.40021 19.3669 2.16 17.8C2.10797 17.5054 2.07125 17.2084 2.05 16.91C2.05 16.91 2.05 16.91 2.05 16.85C2.05 16.55 2.05 16.25 2.05 15.95C2.05 15.65 2.05 15.51 2.05 15.29C2.04012 15.1702 2.04012 15.0498 2.05 14.93C2.06127 14.6852 2.08799 14.4414 2.13 14.2C2.12511 14.1735 2.12511 14.1464 2.13 14.12C2.13 13.88 2.21 13.64 2.26 13.4C2.26 13.34 2.26 13.28 2.26 13.22C2.32 12.98 2.38 12.75 2.45 12.53C2.50279 12.3268 2.56621 12.1265 2.64 11.93C2.63587 11.8934 2.63587 11.8565 2.64 11.82C2.66799 11.7267 2.70482 11.6363 2.75 11.55C2.82 11.35 2.91 11.15 3 10.95C3.09 10.75 3.23 10.46 3.36 10.22V10.17C3.49 9.93999 3.63 9.70999 3.77 9.48999C4.67149 11.7246 6.22481 13.636 8.22777 14.9754C10.2307 16.3149 12.5905 17.0204 15 17C15.7246 16.9997 16.4477 16.9328 17.16 16.8V16.8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.34 11.93C19.698 13.7908 18.6055 15.4639 17.16 16.8C16.4477 16.9328 15.7246 16.9997 15 17C12.6121 16.9972 10.2793 16.282 8.30017 14.9459C6.32101 13.6098 4.78547 11.7137 3.89 9.5C4.16055 9.06925 4.4613 8.65822 4.79 8.27C4.95 8.08 5.11 7.89 5.28 7.71C5.41 7.57 5.55 7.43 5.69 7.3L5.9 7.1C6.21829 6.79944 6.55208 6.51572 6.9 6.25L7.56 5.81001L8.19 5.44C9.30224 7.60588 11.0435 9.38476 13.1851 10.543C15.3267 11.7013 17.7686 12.1848 20.19 11.93H20.34Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 7.99998C21.003 9.33782 20.7799 10.6665 20.34 11.93C17.9186 12.1848 15.4767 11.7013 13.3351 10.543C11.1935 9.38474 9.45224 7.60586 8.34 5.43998C8.55 5.31998 8.77 5.21998 8.99 5.10998L9.66 4.82999C9.87546 4.738 10.0958 4.65788 10.32 4.58998C10.56 4.50998 10.81 4.43999 11.06 4.37999L11.74 4.21999H11.92C12.12 4.17999 12.32 4.14999 12.52 4.12999C12.6889 4.10342 12.8592 4.08673 13.03 4.07999H13.42H14.04C14.3331 4.06481 14.6269 4.06481 14.92 4.07999H15C15.29 4.07999 15.57 4.13998 15.85 4.17998C16.1233 4.21998 16.3833 4.26999 16.63 4.32999C16.7289 4.34269 16.8262 4.36617 16.92 4.39999C17.17 4.45999 17.42 4.52998 17.66 4.60998C17.9 4.68998 18.22 4.79998 18.49 4.90998C18.76 5.01998 19.02 5.12998 19.28 5.25998L19.38 5.30999C19.62 5.42999 19.86 5.55998 20.09 5.69998C20.32 5.83998 20.5 5.94999 20.69 6.07999L20.84 6.17998C20.9377 6.78193 20.9912 7.39023 21 7.99998Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 20C6.54958 20.007 4.15695 19.256 2.15 17.85C2.09797 17.5555 2.06125 17.2584 2.04 16.96C2.04 16.96 2.04 16.96 2.04 16.9C2.04 16.6 2.04 16.3 2.04 16C2.04 15.7 2.04 15.56 2.04 15.34C2.03012 15.2202 2.03012 15.0998 2.04 14.98C2.05127 14.7353 2.07799 14.4914 2.12 14.25C2.11511 14.2236 2.11511 14.1965 2.12 14.17C2.12 13.93 2.2 13.69 2.25 13.45C2.25 13.39 2.25 13.33 2.25 13.27C2.31 13.03 2.37 12.8 2.44 12.58C2.49279 12.3768 2.55621 12.1766 2.63 11.98C2.62587 11.9435 2.62587 11.9066 2.63 11.87C2.65799 11.7767 2.69482 11.6863 2.74 11.6C2.81 11.4 2.9 11.2 2.99 11C3.08 10.8 3.22 10.51 3.35 10.27V10.22C3.48 9.99003 3.62 9.76003 3.76 9.54003C4.02386 9.10477 4.32499 8.69323 4.66 8.31003C4.82 8.12003 4.98 7.93003 5.15 7.75003C5.28 7.61003 5.42 7.47003 5.56 7.34003L5.77 7.14003C6.08829 6.83946 6.42208 6.55574 6.77 6.29003L7.43 5.85002L8.06 5.48003C8.27 5.36003 8.49 5.26003 8.71 5.15003L9.38 4.87003C9.59546 4.77804 9.81579 4.69793 10.04 4.63003C10.28 4.55003 10.53 4.48002 10.78 4.42002L11.46 4.26002H11.64C11.84 4.22002 12.04 4.19002 12.24 4.17002C12.4089 4.14346 12.5792 4.12677 12.75 4.12003H13.14H13.76C14.0531 4.10485 14.3469 4.10485 14.64 4.12003H15C15.29 4.12003 15.57 4.18003 15.85 4.22003C16.1233 4.26003 16.3833 4.31003 16.63 4.37003C16.7289 4.38273 16.8262 4.4062 16.92 4.44002C17.17 4.50002 17.42 4.57003 17.66 4.65003C17.9 4.73003 18.22 4.84003 18.49 4.95003C18.76 5.06003 19.02 5.17003 19.28 5.30003L19.38 5.35002C19.62 5.47002 19.86 5.60003 20.09 5.74003C20.32 5.88003 20.5 5.99003 20.69 6.12003L20.84 6.22003C20.9358 6.80878 20.9892 7.40363 21 8.00003C21 11.1826 19.7357 14.2349 17.4853 16.4853C15.2348 18.7357 12.1826 20 9 20V20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 16C26.0039 18.7003 25.097 21.323 23.4258 23.4441C21.7546 25.5651 19.4169 27.0605 16.7906 27.6885C14.1643 28.3164 11.403 28.0402 8.95308 26.9045C6.50319 25.7688 4.50797 23.84 3.29 21.43C5.31065 23.1905 7.84363 24.255 10.5153 24.4664C13.1869 24.6778 15.8558 24.025 18.1282 22.6042C20.4006 21.1834 22.1562 19.0698 23.136 16.5753C24.1158 14.0809 24.2679 11.3375 23.57 8.75C25.1528 10.835 26.0066 13.3823 26 16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16.96C2 17.26 2.07 17.56 2.11 17.85C2.05797 17.5554 2.02125 17.2584 2 16.96V16.96Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 12C24.0008 14.3994 23.311 16.7483 22.013 18.7663C20.715 20.7843 18.8637 22.3861 16.68 23.3804C14.4963 24.3747 12.0726 24.7195 9.69827 24.3737C7.32393 24.0278 5.0993 23.0059 3.29 21.43C2.72439 20.3039 2.33967 19.0958 2.15 17.85C3.94948 19.1011 6.05722 19.8354 8.24451 19.9734C10.4318 20.1114 12.6151 19.6477 14.5575 18.6327C16.5 17.6177 18.1273 16.0901 19.2631 14.2157C20.3988 12.3413 20.9995 10.1917 21 8.00002C20.9993 7.38074 20.9525 6.76237 20.86 6.15002C21.8925 6.87113 22.8068 7.74825 23.57 8.75002C23.8562 9.80962 24.0008 10.9025 24 12V12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
