"use client";
import React, { useMemo } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { getFormatMedia } from "~/theme/utils/formatMedia";
import { Optional } from "~/types/utility.types";

import { WindowFormat } from "./useBreakpoints.types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BreakpointsContext = React.createContext<Optional<WindowFormat>>(
  WindowFormat.MOBILE
);

export const BreakpointsProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const formatMedia = getFormatMedia(theme);

  const isTablet = useMediaQuery(formatMedia.BREAKPOINT_TABLET);
  const isDesktop = useMediaQuery(formatMedia.BREAKPOINT_DESKTOP);
  const isWideDesktop = useMediaQuery(formatMedia.BREAKPOINT_WIDE_DESKTOP);

  const format = useMemo(() => {
    if (isWideDesktop) {
      return WindowFormat.WIDE_DESKTOP;
    }
    if (isDesktop) {
      return WindowFormat.DESKTOP;
    }
    if (isTablet) {
      return WindowFormat.TABLET;
    }
    return WindowFormat.MOBILE;
  }, [isWideDesktop, isDesktop, isTablet]);

  return (
    <BreakpointsContext.Provider value={format}>
      {children}
    </BreakpointsContext.Provider>
  );
};
