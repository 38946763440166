import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Account24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="7" r="5" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20 19C20 19.9075 19.4467 20.6384 18.0057 21.1914C16.5491 21.7503 14.4455 22 12 22C9.55449 22 7.45086 21.7503 5.99431 21.1914C4.55328 20.6384 4 19.9075 4 19C4 18.0925 4.55328 17.3616 5.99431 16.8086C7.45086 16.2497 9.55449 16 12 16C14.4455 16 16.5491 16.2497 18.0057 16.8086C19.4467 17.3616 20 18.0925 20 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </BaseSVGIcon>
  );
};
