import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Cotton32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M8.73 9.27C8.73 7.34188 9.49595 5.49272 10.8593 4.12933C12.2227 2.76594 14.0719 2 16 2C17.9281 2 19.7773 2.76594 21.1407 4.12933C22.5041 5.49272 23.27 7.34188 23.27 9.27"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M6 21C4.98058 20.4989 4.109 19.7413 3.47084 18.8016C2.83268 17.8619 2.44987 16.7723 2.36 15.64C2.36 13.9506 3.03112 12.3303 4.22573 11.1357C5.42034 9.94111 7.04057 9.26999 8.73 9.26999C9.85492 9.31553 10.9625 9.56279 12 9.99999"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.6 9.85999C21.4355 9.46866 22.3474 9.26717 23.27 9.26999C24.9594 9.26999 26.5797 9.94111 27.7743 11.1357C28.9689 12.3303 29.64 13.9506 29.64 15.64C29.5416 16.7699 29.1553 17.8558 28.5181 18.7941C27.8809 19.7324 27.014 20.4918 26 21"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.7 12.81C22.1413 11.4953 21.2081 10.3741 20.0167 9.58595C18.8254 8.79778 17.4285 8.37753 16 8.37753C14.5715 8.37753 13.1746 8.79778 11.9833 9.58595C10.7919 10.3741 9.85874 11.4953 9.3 12.81"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14 29C14 29.5304 14.2107 30.0391 14.5858 30.4142C14.9609 30.7893 15.4696 31 16 31C16.5304 31 17.0391 30.7893 17.4142 30.4142C17.7893 30.0391 18 29.5304 18 29C18 26 18 25 21 25C26 25 26 24 26 19C24.0864 19.7289 22.0466 20.0689 20 20C17 20 16 23 16 23C16 23 15 20 12 20C9.95341 20.0689 7.91362 19.7289 6 19C6 24 6 25 11 25C14 25 14 26 14 29Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18 20C18 16.25 16.67 14 16 14C15.33 14 14 16.25 14 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
