import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Cookie64 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="64" height="64" fill="none" viewBox="0 0 64 64" {...props}>
      <path
        d="M57.14 29.8498C55.9167 30.9405 54.4039 31.6538 52.7841 31.9036C51.1644 32.1534 49.507 31.9291 48.0119 31.2577C46.5169 30.5863 45.2481 29.4965 44.3587 28.1199C43.4694 26.7433 42.9975 25.1387 43 23.4998C43.0027 22.9962 43.0529 22.494 43.15 21.9998L42.34 21.5598C41.4031 21.8903 40.4066 22.0176 39.4168 21.9332C38.4269 21.8488 37.4664 21.5546 36.5989 21.0703C35.7315 20.586 34.9772 19.9226 34.3859 19.1242C33.7947 18.3259 33.3802 17.4108 33.17 16.4398L32.94 16.3098C31.8595 17.5076 30.442 18.3508 28.8738 18.7286C27.3055 19.1065 25.6596 19.0014 24.1521 18.4272C22.6446 17.853 21.3459 16.8364 20.4265 15.5109C19.507 14.1854 19.0098 12.6129 19 10.9998C19 10.8198 19 10.6498 19 10.4698C15.048 12.7515 11.7661 16.0332 9.48417 19.985C7.20222 23.9369 6.00059 28.4198 6 32.9831C5.99942 37.5465 7.19991 42.0297 9.48084 45.9821C11.7618 49.9346 15.0428 53.2171 18.9942 55.4998C22.9456 57.7825 27.4283 58.985 31.9917 58.9865C36.5551 58.9879 41.0384 57.7883 44.9913 55.5081C48.9442 53.228 52.2274 49.9476 54.5108 45.9966C56.7943 42.0456 57.9977 37.5632 58 32.9998C58.0004 32.0778 57.9504 31.1564 57.85 30.2398L57.14 29.8498Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M36 12C38.2091 12 40 10.2091 40 8C40 5.79086 38.2091 4 36 4C33.7909 4 32 5.79086 32 8C32 10.2091 33.7909 12 36 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18 31C20.2091 31 22 29.2091 22 27C22 24.7909 20.2091 23 18 23C15.7909 23 14 24.7909 14 27C14 29.2091 15.7909 31 18 31Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M19 46C21.2091 46 23 44.2091 23 42C23 39.7909 21.2091 38 19 38C16.7909 38 15 39.7909 15 42C15 44.2091 16.7909 46 19 46Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M45 43C47.2091 43 49 41.2091 49 39C49 36.7909 47.2091 35 45 35C42.7909 35 41 36.7909 41 39C41 41.2091 42.7909 43 45 43Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M32 52C34.2091 52 36 50.2091 36 48C36 45.7909 34.2091 44 32 44C29.7909 44 28 45.7909 28 48C28 50.2091 29.7909 52 32 52Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M27 30C27.5523 30 28 29.5523 28 29C28 28.4477 27.5523 28 27 28C26.4477 28 26 28.4477 26 29C26 29.5523 26.4477 30 27 30Z"
        fill="currentColor"
      />
      <path
        d="M28 37C28.5523 37 29 36.5523 29 36C29 35.4477 28.5523 35 28 35C27.4477 35 27 35.4477 27 36C27 36.5523 27.4477 37 28 37Z"
        fill="currentColor"
      />
      <path
        d="M35 32C35.5523 32 36 31.5523 36 31C36 30.4477 35.5523 30 35 30C34.4477 30 34 30.4477 34 31C34 31.5523 34.4477 32 35 32Z"
        fill="currentColor"
      />
      <path
        d="M42 49C42.5523 49 43 48.5523 43 48C43 47.4477 42.5523 47 42 47C41.4477 47 41 47.4477 41 48C41 48.5523 41.4477 49 42 49Z"
        fill="currentColor"
      />
      <path
        d="M23 52C23.5523 52 24 51.5523 24 51C24 50.4477 23.5523 50 23 50C22.4477 50 22 50.4477 22 51C22 51.5523 22.4477 52 23 52Z"
        fill="currentColor"
      />
      <path
        d="M12 36C12.5523 36 13 35.5523 13 35C13 34.4477 12.5523 34 12 34C11.4477 34 11 34.4477 11 35C11 35.5523 11.4477 36 12 36Z"
        fill="currentColor"
      />
      <path
        d="M36 39C36.5523 39 37 38.5523 37 38C37 37.4477 36.5523 37 36 37C35.4477 37 35 37.4477 35 38C35 38.5523 35.4477 39 36 39Z"
        fill="currentColor"
      />
      <path
        d="M47 11C47.5523 11 48 10.5523 48 10C48 9.44772 47.5523 9 47 9C46.4477 9 46 9.44772 46 10C46 10.5523 46.4477 11 47 11Z"
        fill="currentColor"
      />
      <path
        d="M53 15C53.5523 15 54 14.5523 54 14C54 13.4477 53.5523 13 53 13C52.4477 13 52 13.4477 52 14C52 14.5523 52.4477 15 53 15Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
