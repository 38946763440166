import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Silk32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M19 19.86C19 24.35 17.66 28 16 28C14.34 28 13 24.35 13 19.86C13 18.52 13 17.26 13.08 16.15C13.08 16.9244 13.3876 17.6671 13.9352 18.2147C14.4828 18.7624 15.2256 19.07 16 19.07C16.7744 19.07 17.5172 18.7624 18.0648 18.2147C18.6124 17.6671 18.92 16.9244 18.92 16.15C19 17.26 19 18.5 19 19.86Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M19 15.36C18.9986 15.6352 18.9719 15.9097 18.92 16.18C18.92 16.9544 18.6124 17.6971 18.0648 18.2448C17.5172 18.7924 16.7744 19.1 16 19.1C15.2256 19.1 14.4829 18.7924 13.9352 18.2448C13.3876 17.6971 13.08 16.9544 13.08 16.18C13.0282 15.9097 13.0014 15.6352 13 15.36C12.9868 14.4023 13.3127 13.4707 13.92 12.73L14.09 12.54C14.5205 12.1171 15.0734 11.8407 15.67 11.75H15.98H16.29C16.8866 11.8407 17.4395 12.1171 17.87 12.54V12.54L18.04 12.73C18.6617 13.4651 19.002 14.3972 19 15.36Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.32 11.5L14.17 11.69C14.0697 11.3662 14.0191 11.029 14.02 10.69C14.02 9.19001 14.92 7.98001 16.02 7.98001C17.12 7.98001 18.02 9.19001 18.02 10.69C18.0209 11.029 17.9703 11.3662 17.87 11.69L17.7 11.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18 12.61C20 8.38999 26.1 2.35999 29.14 4.40999C32.18 6.45999 30.99 13.04 29.14 15.68C26.83 19 22.9 20 19.01 16.68"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14 12.61C12 8.38999 5.9 2.35999 2.86 4.40999C-0.18 6.45999 1.01 13.04 2.86 15.68C5.17 19 9.1 20 12.99 16.68"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18.15 25.25C20 29.75 25 29.75 27 27.6C28 26.53 29 23.3 26 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.85 25.25C12 29.75 7 29.75 5 27.6C4 26.53 3 23.3 6 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
