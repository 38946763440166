import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const CzFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect
        x="1.25"
        y="4.25"
        width="21.5"
        height="15.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20H23V12H1V20Z"
        fill="#E8252A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4L11.4762 12L1 20V4Z"
        fill="#17579E"
      />
    </BaseSVGIcon>
  );
};
