import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const RoadSign32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V4H20L23 7.5L20 11H13V13H20V20H13V22H11V20H4L1 16.5L4 13H11V11H4V4H11V3C11 2.44772 11.4477 2 12 2ZM19.08 6L20.37 7.5L19.08 9H6V6H19.08ZM18 18H4.92L3.63 16.5L4.92 15H18V18Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};

export const RoadSign24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V4H20L23 7.5L20 11H13V13H20V20H13V22H11V20H4L1 16.5L4 13H11V11H4V4H11V3C11 2.44772 11.4477 2 12 2ZM19.08 6L20.37 7.5L19.08 9H6V6H19.08ZM18 18H4.92L3.63 16.5L4.92 15H18V18Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
