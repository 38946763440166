import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const SelectShop24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M19.0328 13.3523C18.0894 16.4905 16.7226 20.0303 15.6753 21.9132C15.8512 22.072 15.9748 22.2553 15.875 22.4704C15.7021 22.8432 15.0033 23.131 14.6269 22.9597C12.4124 21.9518 7.94279 18.1937 6.66568 10.988C6.25314 8.66045 6.6363 6.70005 7.53428 5.16604C8.42842 3.63859 9.80599 2.58002 11.3078 2.0031C14.2479 0.873648 17.9635 1.53893 19.3881 4.5784C19.9429 5.76202 20.0728 7.19797 19.9655 8.68249C19.8575 10.1762 19.5046 11.7829 19.0328 13.3523Z"
        fill="#7FD6EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4166 4.0312C11.6758 3.99268 11.9202 4.13599 12.0179 4.36454C12.9319 4.83034 14.125 5.74917 14.125 7.55389C14.125 9.8367 12.2648 10.897 11.125 10.897C10.5893 10.897 9.80494 10.7313 9.1408 10.2238C8.45052 9.69634 7.9375 8.83419 7.9375 7.55389C7.9375 6.26853 8.45485 5.40512 9.19375 4.85617C9.68145 4.49385 10.2433 4.28496 10.7598 4.15834C10.903 4.03742 11.1035 3.99147 11.2931 4.05047C11.335 4.04361 11.3762 4.0372 11.4166 4.0312ZM11.125 9.78264C8.12516 9.78264 8.73266 5.73045 11.1808 5.20561C13.08 6.01149 13.9311 9.78264 11.125 9.78264Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1753 20.9264C13.3512 21.0851 13.4748 21.2685 13.375 21.4836C13.2021 21.8564 12.5033 22.1442 12.1269 21.9729C9.91237 20.9649 5.44279 17.2069 4.16568 10.0012C3.75314 7.67363 4.1363 5.71324 5.03428 4.17922C5.92842 2.65177 7.30599 1.59321 8.80778 1.01628C11.7479 -0.113168 15.4635 0.552113 16.8881 3.59159C17.4429 4.77521 17.5728 6.21115 17.4655 7.69567C17.3575 9.18934 17.0046 10.7961 16.5328 12.3655C15.5894 15.5037 14.2226 19.0435 13.1753 20.9264ZM5.64308 9.7443C5.28492 7.72353 5.62964 6.12425 6.33191 4.92455C7.03803 3.71829 8.13311 2.86912 9.35015 2.40159C11.8477 1.44212 14.5545 2.14103 15.5276 4.21727C15.9319 5.07994 16.0675 6.23027 15.9693 7.58958C15.8717 8.93975 15.5482 10.4344 15.0952 11.9415C14.1888 14.9564 12.791 17.9339 11.7832 19.7457C11.7369 19.8288 11.7085 19.9168 11.6965 20.0055C9.67976 18.6058 6.63499 15.3408 5.64308 9.7443ZM13.0259 20.804C12.976 20.7357 12.9247 20.6733 12.8869 20.701C12.8936 20.7059 12.9004 20.7109 12.9071 20.7158C12.946 20.7443 12.9861 20.7736 13.0259 20.804ZM13.1753 20.9264C13.1487 20.9742 13.0983 20.9044 13.0441 20.8291C13.0381 20.8207 13.032 20.8123 13.0259 20.804C13.0772 20.8431 13.1281 20.8839 13.1753 20.9264Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
