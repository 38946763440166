import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Pinterest24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M23.9921 12.0006C23.9921 18.6262 18.6256 24.0006 12 24.0006C5.37443 24.0006 0 18.6262 0 12.0006C0 5.37504 5.36656 0.000610352 12 0.000610352C18.6334 0.000610352 23.9921 5.37504 23.9921 12.0006Z"
        fill="white"
      />
      <path
        d="M11.9927 0C5.36717 0 0.000610352 5.37443 0.000610352 12C0.000610352 17.0833 3.16389 21.4269 7.62553 23.1738C7.52323 22.2216 7.42881 20.7659 7.66487 19.7272C7.8852 18.7987 9.0734 13.7705 9.0734 13.7705C9.0734 13.7705 8.71143 13.0544 8.71143 11.9921C8.71143 10.3239 9.6793 9.08066 10.8832 9.08066C11.9062 9.08066 12.4019 9.8518 12.4019 10.7725C12.4019 11.8033 11.7488 13.3377 11.4104 14.7698C11.1272 15.9659 12.0085 16.9416 13.1888 16.9416C15.3213 16.9416 16.958 14.6911 16.958 11.4492C16.958 8.57705 14.8963 6.57049 11.9455 6.57049C8.53045 6.57049 6.52389 9.12787 6.52389 11.7797C6.52389 12.8105 6.91733 13.92 7.41307 14.518C7.5075 14.6361 7.52323 14.7384 7.49963 14.8643C7.4052 15.242 7.20848 16.0603 7.16913 16.2256C7.11405 16.4459 6.99602 16.4931 6.76782 16.3829C5.27274 15.6826 4.32848 13.4951 4.32848 11.7325C4.32848 7.94754 7.08258 4.46951 12.2603 4.46951C16.4229 4.46951 19.657 7.43607 19.657 11.402C19.657 15.541 17.0524 18.8616 13.4327 18.8616C12.2131 18.8616 11.0721 18.2321 10.6786 17.4846C10.6786 17.4846 10.0806 19.7744 9.9311 20.3331C9.66356 21.3718 8.93176 22.678 8.43602 23.4807C9.56913 23.8111 10.7573 24 11.9927 24C18.6183 24 23.9927 18.6256 23.9927 12C23.9927 5.37443 18.6262 0 11.9927 0Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
