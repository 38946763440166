import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Cart24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.77 6.37005C22.6769 6.25534 22.5596 6.16266 22.4265 6.09869C22.2934 6.03471 22.1477 6.00103 22 6.00005H5.44L5 2.86005C4.96604 2.61977 4.84587 2.40004 4.66188 2.24181C4.4779 2.08359 4.24265 1.99766 4 2.00005H2C1.73478 2.00005 1.48043 2.1054 1.29289 2.29294C1.10536 2.48048 1 2.73483 1 3.00005C1 3.26526 1.10536 3.51962 1.29289 3.70716C1.48043 3.89469 1.73478 4.00005 2 4.00005H3.13L4.64 14.57C4.77712 15.5225 5.25298 16.3936 5.98033 17.0236C6.70767 17.6536 7.63775 18.0002 8.6 18H17.54C18.4649 17.9993 19.361 17.678 20.0757 17.091C20.7905 16.5039 21.2796 15.6872 21.46 14.78L23 7.20005C23.0259 7.05331 23.0187 6.90262 22.9789 6.75902C22.9391 6.61541 22.8678 6.48252 22.77 6.37005ZM19.5 14.37C19.4086 14.8304 19.1581 15.244 18.7925 15.5383C18.4268 15.8327 17.9693 15.989 17.5 15.98H8.6C8.11469 15.9848 7.64421 15.813 7.27624 15.4965C6.90827 15.1801 6.66792 14.7406 6.6 14.26L5.72 8.00005H20.78L19.5 14.37Z"
        fill="currentColor"
      />
      <path
        d="M7.5 22C8.32843 22 9 21.3284 9 20.5C9 19.6716 8.32843 19 7.5 19C6.67157 19 6 19.6716 6 20.5C6 21.3284 6.67157 22 7.5 22Z"
        fill="currentColor"
      />
      <path
        d="M18.5 22C19.3284 22 20 21.3284 20 20.5C20 19.6716 19.3284 19 18.5 19C17.6716 19 17 19.6716 17 20.5C17 21.3284 17.6716 22 18.5 22Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
