import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Download = (props: IconProps) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.0001 16.41L17.7101 10.7C18.1001 10.31 18.1001 9.68 17.7101 9.29C17.3201 8.9 16.6901 8.9 16.3001 9.29L13.0101 12.58V4C13.0101 3.45 12.5601 3 12.0101 3C11.4601 3 11.0101 3.45 11.0101 4V12.59L7.72008 9.3C7.33008 8.91 6.70008 8.91 6.31008 9.3C5.92008 9.69 5.92008 10.32 6.31008 10.71L12.0201 16.42L12.0001 16.41Z"
      fill="black"
    />
    <path
      d="M20 14C19.45 14 19 14.45 19 15V19H5V15C5 14.45 4.55 14 4 14C3.45 14 3 14.45 3 15V21H21V15C21 14.45 20.55 14 20 14Z"
      fill="black"
    />
  </BaseSVGIcon>
);
