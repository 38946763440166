import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Search24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M10.5 1C7.98044 1 5.56408 2.00089 3.78249 3.78249C2.00089 5.56408 1 7.98044 1 10.5C1 13.0196 2.00089 15.4359 3.78249 17.2175C5.56408 18.9991 7.98044 20 10.5 20C13.0196 20 15.4359 18.9991 17.2175 17.2175C18.9991 15.4359 20 13.0196 20 10.5C20 7.98044 18.9991 5.56408 17.2175 3.78249C15.4359 2.00089 13.0196 1 10.5 1ZM10.5 18C8.51088 18 6.60322 17.2098 5.1967 15.8033C3.79018 14.3968 3 12.4891 3 10.5C3 8.51088 3.79018 6.60322 5.1967 5.1967C6.60322 3.79018 8.51088 3 10.5 3C12.4891 3 14.3968 3.79018 15.8033 5.1967C17.2098 6.60322 18 8.51088 18 10.5C18 12.4891 17.2098 14.3968 15.8033 15.8033C14.3968 17.2098 12.4891 18 10.5 18Z"
        fill="currentColor"
      />
      <path
        d="M22.7099 21.2899L19.7099 18.2899C19.617 18.1962 19.5064 18.1218 19.3845 18.071C19.2627 18.0203 19.132 17.9941 18.9999 17.9941C18.8679 17.9941 18.7372 18.0203 18.6154 18.071C18.4935 18.1218 18.3829 18.1962 18.2899 18.2899C18.1962 18.3829 18.1218 18.4935 18.071 18.6154C18.0203 18.7372 17.9941 18.8679 17.9941 18.9999C17.9941 19.132 18.0203 19.2627 18.071 19.3845C18.1218 19.5064 18.1962 19.617 18.2899 19.7099L21.2899 22.7099C21.3834 22.8026 21.4942 22.8759 21.616 22.9257C21.7379 22.9755 21.8683 23.0007 21.9999 22.9999C22.1315 23.0007 22.262 22.9755 22.3838 22.9257C22.5057 22.8759 22.6165 22.8026 22.7099 22.7099C22.8037 22.617 22.8781 22.5064 22.9288 22.3845C22.9796 22.2627 23.0057 22.132 23.0057 21.9999C23.0057 21.8679 22.9796 21.7372 22.9288 21.6154C22.8781 21.4935 22.8037 21.3829 22.7099 21.2899Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
