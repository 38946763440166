import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const BeFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
      <rect x="11.4762" y="4" width="11.5238" height="16" fill="#FF4453" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20H8.33333V4H1V20Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33331 20H15.6666V4H8.33331V20Z"
        fill="#FFCF3C"
      />
    </BaseSVGIcon>
  );
};
