import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Youtube24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M23.4955 6.19123C23.2227 5.15487 22.4045 4.33669 21.3682 4.05032C19.5 3.54578 12 3.54578 12 3.54578C12 3.54578 4.5 3.54578 2.61818 4.05032C1.58182 4.32305 0.777273 5.14123 0.490909 6.19123C0 8.07305 0 12.0003 0 12.0003C0 12.0003 0 15.9276 0.504545 17.8094C0.777273 18.8458 1.59545 19.664 2.63182 19.9503C4.5 20.4549 12 20.4549 12 20.4549C12 20.4549 19.5 20.4549 21.3818 19.9503C22.4182 19.6776 23.2227 18.8594 23.5091 17.8094C24 15.9276 24 12.0003 24 12.0003C24 12.0003 24 8.07305 23.4955 6.19123Z"
        fill="black"
      />
      <path
        d="M9.54544 15.573L15.8182 12.0003L9.54544 8.42755V15.573Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};
