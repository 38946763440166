import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Linen32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M17.17 12.79C18.64 9.53 21.74 7.49 22.32 5.64C24.16 -0.19 15.87 2.73 15.87 2.73C15.87 2.73 8.5 -0.19 9.42 5.64C9.73 7.56 13.11 9.53 14.58 12.79"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.64 13.63C11.11 11.18 10.19 7.49001 8.64 6.35001C3.9 2.73001 4 11.8 4 11.8C4 11.8 -0.95001 18.17 4.67999 19.06C6.53999 19.35 9.39 16.63 12.83 16.19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.33 17.38C10.33 19.11 6.62001 18.88 5.11001 20.01C0.310006 23.61 8.67 26.3 8.67 26.3C8.67 26.3 12.98 33.15 15.54 27.87C16.38 26.14 14.76 22.48 15.42 18.95"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16.66 18.84C17.32 22.37 15.97 25.91 16.54 27.76C18.36 33.6 23.41 26.19 23.41 26.19C23.41 26.19 31 24.06 27 19.9C25.66 18.53 21.81 19 18.78 17.26"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M19 16C22.44 16.44 25.27 18.87 27.14 18.88C33.06 18.88 27.82 11.62 27.82 11.62C27.82 11.62 28.22 3.44999 23.16 6.15999C21.49 7.04999 20.73 11 18.16 13.45"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
