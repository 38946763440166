import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Twitter24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M21.5337 7.10531C21.5435 7.31986 21.5435 7.52465 21.5435 7.7392C21.5532 14.2439 16.6011 21.753 7.54509 21.753C4.87409 21.753 2.25183 20.9826 0 19.5393C0.389927 19.588 0.779854 19.6075 1.16978 19.6075C3.38262 19.6075 5.53696 18.8664 7.28188 17.4913C5.17628 17.4523 3.32413 16.0773 2.68075 14.0683C3.42161 14.2146 4.18197 14.1854 4.90333 13.9806C2.61251 13.532 0.965069 11.5133 0.955321 9.16301C0.955321 9.14351 0.955321 9.124 0.955321 9.1045C1.63769 9.48483 2.4078 9.69938 3.18765 9.71888C1.03331 8.27557 0.360682 5.39869 1.66694 3.14594C4.17222 6.22762 7.85703 8.09028 11.8148 8.29507C11.4151 6.58845 11.961 4.79405 13.238 3.58479C15.2169 1.72213 18.3363 1.81965 20.208 3.79934C21.3095 3.58479 22.3721 3.1752 23.3371 2.59982C22.9667 3.74082 22.1966 4.70628 21.173 5.32067C22.1478 5.20364 23.1032 4.94034 24 4.55025C23.3371 5.54497 22.4988 6.40316 21.5337 7.10531Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
