import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Pin24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3429 23.3143C14.929 23.3143 20.7478 15.5559 20.7478 9.09061C20.7478 4.44872 16.9848 0.685715 12.3429 0.685715C7.70096 0.685715 3.93796 4.44872 3.93796 9.09061C3.93796 15.5559 9.75673 23.3143 12.3429 23.3143ZM12.3429 12.3233C14.1282 12.3233 15.5755 10.876 15.5755 9.09061C15.5755 7.30527 14.1282 5.85796 12.3429 5.85796C10.5575 5.85796 9.1102 7.30527 9.1102 9.09061C9.1102 10.876 10.5575 12.3233 12.3429 12.3233Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7783 23.5684C13.3192 23.8222 12.8277 24 12.3429 24C11.8581 24 11.3666 23.8222 10.9075 23.5684C10.4406 23.3103 9.9577 22.9461 9.47676 22.5079C8.5144 21.6311 7.50801 20.4106 6.59492 18.9995C4.77729 16.1904 3.25226 12.5042 3.25226 9.09061C3.25226 4.07001 7.32226 0 12.3429 0C17.3635 0 21.4335 4.07001 21.4335 9.09061C21.4335 12.5042 19.9084 16.1904 18.0908 18.9995C17.1777 20.4106 16.1713 21.6311 15.209 22.5079C14.728 22.9461 14.2451 23.3103 13.7783 23.5684ZM12.3429 11.6376C13.7495 11.6376 14.8898 10.4972 14.8898 9.09061C14.8898 7.68398 13.7495 6.54367 12.3429 6.54367C10.9362 6.54367 9.79593 7.68398 9.79593 9.09061C9.79593 10.4972 10.9362 11.6376 12.3429 11.6376ZM20.7478 9.09061C20.7478 15.5559 14.929 23.3143 12.3429 23.3143C9.75675 23.3143 3.93797 15.5559 3.93797 9.09061C3.93797 4.44872 7.70097 0.685714 12.3429 0.685714C16.9848 0.685714 20.7478 4.44872 20.7478 9.09061ZM15.5755 9.09061C15.5755 10.876 14.1282 12.3233 12.3429 12.3233C10.5575 12.3233 9.11022 10.876 9.11022 9.09061C9.11022 7.30527 10.5575 5.85796 12.3429 5.85796C14.1282 5.85796 15.5755 7.30527 15.5755 9.09061Z"
        fill="transparent"
      />
    </BaseSVGIcon>
  );
};
