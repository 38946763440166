export const ROUTES = {
  HOME: "/",
  API: "/api",
  ARTICLE: "/a",
  NEWSROOM: "/e",
  ERROR: "/_error",
  HEALTH: "/health",
  ROBOTS: "/robots.txt",
};

export const DEFAULT_ROUTE = ROUTES.HOME;

export const DELIVERY_KEY_MAPPERS = [
  { from: "/article", to: ROUTES.ARTICLE },
  { from: "/newsroom", to: ROUTES.NEWSROOM },
  { from: "/gcp", to: "" },
];
