export enum Locales {
  "en-gb" = "en-gb",
  "fr-be" = "fr-be",
  "nl-be" = "nl-be",
  "cs-cz" = "cs-cz",
  "de-de" = "de-de",
  "es-es" = "es-es",
  "fr-fr" = "fr-fr",
  "en-ie" = "en-ie",
  "it-it" = "it-it",
  "nl-nl" = "nl-nl",
  "pl-pl" = "pl-pl",
  "pt-pt" = "pt-pt",
  "sl-si" = "sl-si",
  "en-us" = "en-us",
  "de-at" = "de-at",
  "ro-ro" = "ro-ro",
  "sk-sk" = "sk-sk",
  "hu-hu" = "hu-hu",
  UNKNOWN = "unknown",
}

enum CurrencyCodes {
  GBP = "GBP",
  EUR = "EUR",
  CZK = "CZK",
  PLN = "PLN",
  USD = "USD",
  LEI = "LEI",
}

enum LogoPaths {
  PRIMARK = "/assets/images/logo.svg",
  PENNEYS = "/assets/images/logo-ie.png",
}

export const LOCALES = Object.keys(Locales).filter(
  (locale) => locale !== Locales.UNKNOWN
);
export const DEFAULT_LOCALE = Locales["en-gb"];
export const UNKNOWN_LOCALE = Locales.UNKNOWN;
export const DEFAULT_CURRENCY_CODE = CurrencyCodes.GBP;

export const getCurrencyCodeByLocale = (locale: string): CurrencyCodes => {
  switch (locale) {
    case Locales["en-gb"]: {
      return CurrencyCodes.GBP;
    }
    case Locales["fr-be"]:
    case Locales["nl-be"]:
    case Locales["de-de"]:
    case Locales["es-es"]:
    case Locales["fr-fr"]:
    case Locales["en-ie"]:
    case Locales["it-it"]:
    case Locales["nl-nl"]:
    case Locales["pt-pt"]:
    case Locales["sl-si"]:
    case Locales["de-at"]:
    case Locales["sk-sk"]: {
      return CurrencyCodes.EUR;
    }
    case Locales["cs-cz"]: {
      return CurrencyCodes.CZK;
    }
    case Locales["pl-pl"]: {
      return CurrencyCodes.PLN;
    }
    case Locales["en-us"]: {
      return CurrencyCodes.USD;
    }
    case Locales["ro-ro"]: {
      return CurrencyCodes.LEI;
    }
    default:
      return DEFAULT_CURRENCY_CODE;
  }
};

const isLocaleFromAmplience = (localeInPathname: string): boolean => {
  // regular locale -> 'en-gb'
  // Amplience locale -> 'en-GB'
  return localeInPathname !== localeInPathname.toLowerCase();
};

export const getLocaleFromPathname = (
  pathname: string
): {
  matchedLocale: string;
  isLocaleFromAmplience: boolean;
} => {
  const matchedLocale = LOCALES.find(
    (locale) =>
      pathname.toLowerCase().startsWith(`/${locale}/`) ||
      pathname.toLowerCase() === `/${locale}`
  );

  return {
    matchedLocale: matchedLocale ?? Locales.UNKNOWN,
    isLocaleFromAmplience: matchedLocale
      ? isLocaleFromAmplience(pathname.split("/")[1])
      : false,
  };
};

export const getCountryCodeByLocale = (locale: string): string => {
  return locale.split("-")?.[1]?.toUpperCase();
};

export const getLanguageCodeByLocale = (locale: string): string => {
  return locale.split("-")?.[0]?.toLowerCase();
};

export const getLogoPathByLocale = (locale: string): LogoPaths => {
  if (locale.endsWith("-ie")) {
    return LogoPaths.PENNEYS;
  }
  return LogoPaths.PRIMARK;
};
