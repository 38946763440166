import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const FavoriteSelected24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.4578 6.59133C21.9691 6.08683 21.3889 5.68663 20.7503 5.41358C20.1117 5.14054 19.4272 5 18.7359 5C18.0446 5 17.3601 5.14054 16.7215 5.41358C16.0829 5.68663 15.5026 6.08683 15.0139 6.59133L13.9997 7.63785L12.9855 6.59133C11.9984 5.57276 10.6596 5.00053 9.26361 5.00053C7.86761 5.00053 6.52879 5.57276 5.54168 6.59133C4.55456 7.6099 4 8.99139 4 10.4319C4 11.8723 4.55456 13.2538 5.54168 14.2724L6.55588 15.3189L13.9997 23L21.4436 15.3189L22.4578 14.2724C22.9467 13.7681 23.3346 13.1694 23.5992 12.5105C23.8638 11.8515 24 11.1452 24 10.4319C24 9.71857 23.8638 9.01225 23.5992 8.35328C23.3346 7.69431 22.9467 7.09559 22.4578 6.59133Z"
        fill="#00AFDB"
        fillOpacity="0.5"
      />
      <path
        d="M20.4578 4.59133C19.9691 4.08683 19.3889 3.68663 18.7503 3.41358C18.1117 3.14054 17.4272 3 16.7359 3C16.0446 3 15.3601 3.14054 14.7215 3.41358C14.0829 3.68663 13.5026 4.08683 13.0139 4.59133L11.9997 5.63785L10.9855 4.59133C9.99842 3.57276 8.6596 3.00053 7.26361 3.00053C5.86761 3.00053 4.52879 3.57276 3.54168 4.59133C2.55456 5.6099 2 6.99139 2 8.43187C2 9.87235 2.55456 11.2538 3.54168 12.2724L4.55588 13.3189L11.9997 21L19.4436 13.3189L20.4578 12.2724C20.9467 11.7681 21.3346 11.1694 21.5992 10.5105C21.8638 9.85148 22 9.14517 22 8.43187C22 7.71857 21.8638 7.01225 21.5992 6.35328C21.3346 5.69431 20.9467 5.09559 20.4578 4.59133V4.59133Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
