import { Theme } from "@mui/material/styles";

export enum FormatMedia {
  BREAKPOINT_NARROW_PHONE = "BREAKPOINT_NARROW_PHONE",
  BREAKPOINT_TABLET = "BREAKPOINT_TABLET",
  BREAKPOINT_DESKTOP = "BREAKPOINT_DESKTOP",
  BREAKPOINT_LARGE_DESKTOP = "BREAKPOINT_LARGE_DESKTOP",
  BREAKPOINT_WIDE_DESKTOP = "BREAKPOINT_WIDE_DESKTOP",
}

export const getFormatMedia = (theme: Theme): Record<FormatMedia, string> => ({
  [FormatMedia.BREAKPOINT_NARROW_PHONE]: theme.breakpoints.up("xs"),
  [FormatMedia.BREAKPOINT_TABLET]: theme.breakpoints.up("sm"),
  [FormatMedia.BREAKPOINT_DESKTOP]: theme.breakpoints.up("md"),
  [FormatMedia.BREAKPOINT_LARGE_DESKTOP]: theme.breakpoints.up("lg"),
  [FormatMedia.BREAKPOINT_WIDE_DESKTOP]: theme.breakpoints.up("xl"),
});
