import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const ColorMark32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="2 2 32 32" {...props}>
      <path d="M2 2H34V34H2V2Z" fill="currentColor" />
      <path
        d="M2 2V0H0V2H2ZM34 2H36V0H34V2ZM34 34V36H36V34H34ZM2 34H0V36H2V34ZM2 4H34V0H2V4ZM32 2V34H36V2H32ZM34 32H2V36H34V32ZM4 34V2H0V34H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};

export const ColorMarkWhiteChecked32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="2 2 32 32" {...props}>
      <path d="M2 2H34V34H2V2Z" fill="currentColor" />
      <path
        d="M9 18L15 24L27 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 2V0H0V2H2ZM34 2H36V0H34V2ZM34 34V36H36V34H34ZM2 34H0V36H2V34ZM2 4H34V0H2V4ZM32 2V34H36V2H32ZM34 32H2V36H34V32ZM4 34V2H0V34H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};

export const ColorMarkBlackChecked32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="2 2 32 32" {...props}>
      <path d="M2 2H34V34H2V2Z" fill="currentColor" />
      <path
        d="M9 18L15 24L27 12"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 2V0H0V2H2ZM34 2H36V0H34V2ZM34 34V36H36V34H34ZM2 34H0V36H2V34ZM2 4H34V0H2V4ZM32 2V34H36V2H32ZM34 32H2V36H34V32ZM4 34V2H0V34H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};

export const ColorMark44 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="40" height="40" fill="none" viewBox="2 2 40 40" {...props}>
      <path d="M2 2H42V42H2V2Z" fill="currentColor" />
      <path
        d="M2 2V0H0V2H2ZM42 2H44V0H42V2ZM42 42V44H44V42H42ZM2 42H0V44H2V42ZM2 4H42V0H2V4ZM40 2V42H44V2H40ZM42 40H2V44H42V40ZM4 42V2H0V42H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};

export const ColorMarkWhiteChecked44 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="40" height="40" fill="none" viewBox="2 2 40 40" {...props}>
      <path d="M2 2H42V42H2V2Z" fill="currentColor" />
      <path
        d="M13 22L19 28L31 16"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 2V0H0V2H2ZM42 2H44V0H42V2ZM42 42V44H44V42H42ZM2 42H0V44H2V42ZM2 4H42V0H2V4ZM40 2V42H44V2H40ZM42 40H2V44H42V40ZM4 42V2H0V42H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};

export const ColorMarkBlackChecked44 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="40" height="40" fill="none" viewBox="2 2 40 40" {...props}>
      <path d="M2 2H42V42H2V2Z" fill="currentColor" />
      <path
        d="M13 22L19 28L31 16"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 2V0H0V2H2ZM42 2H44V0H42V2ZM42 42V44H44V42H42ZM2 42H0V44H2V42ZM2 4H42V0H2V4ZM40 2V42H44V2H40ZM42 40H2V44H42V40ZM4 42V2H0V42H4Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};
