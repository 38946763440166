import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const GiftCard24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M2.58841 13.0259L13.0253 2.58896C13.8063 1.80791 15.0727 1.80791 15.8537 2.58896L21.4116 8.14682C22.1926 8.92787 22.1926 10.1942 21.4116 10.9752L10.9747 21.4121C10.1936 22.1932 8.92731 22.1932 8.14627 21.4121L2.58841 15.8543C1.80736 15.0732 1.80736 13.8069 2.58841 13.0259Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20 6L6 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
