import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Editorial24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.9974 1.99475L10.9993 4.99288L18.9966 12.9903L21.9948 9.99213L13.9974 1.99475Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18 12L15 20L3 21L4 9.00003L12 6.00003"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11 13L3 21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11 15C12.1046 15 13 14.1046 13 13C13 11.8955 12.1046 11 11 11C9.89543 11 9 11.8955 9 13C9 14.1046 9.89543 15 11 15Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
