import React, {
  ImgHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useTheme, styled } from "@mui/material";
import dynamic from "next/dynamic";

import { Image as ImageType } from "~/bff/types/Image";
import { getImageSrcSet, getImageSrcSetWithAmplienceTemplates } from "~/utils/image";
import { prepareTextAttribute } from "~/utils/prepareTextAttribute";
import { getTestAutomationProps } from "~/utils/testUtils";

import { ImageProps, ImageTemplates, ResponsiveImageConfig } from "./Image.types";

// eslint-disable-next-line @typescript-eslint/promise-function-async
const DynamicMarkdown = dynamic(() =>
  import("~/components/Markdown").then((mod) => mod.Markdown)
);

const StyledImage = styled("img")({
  display: "block",
});

export type ImagePropsExtended = ImageProps<string> &
  ImgHTMLAttributes<Element> & {
    imageTemplates?: ImageTemplates;
    responsiveImageConfig?: ResponsiveImageConfig;
    fallbacks?: string[];
  } & Pick<ImageType, "ariaDetailsDescription">;

export const Image = ({
  responsiveImageConfig,
  className,
  src,
  alt,
  title,
  fallbacks,
  ariaDetailsDescription,
  imageTemplates,
  ...props
}: ImagePropsExtended) => {
  const theme = useTheme();

  const fallbacksRef = useRef(fallbacks);

  const [hasRendered, setHasRendered] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement>): void => {
      event.currentTarget.onerror = null;
      const newSrc = fallbacksRef.current?.shift() ?? "/assets/images/no-image.png";
      event.currentTarget.src = newSrc;
      event.currentTarget.srcset = newSrc;
    },
    []
  );

  // solution for SSR img event issue: https://github.com/facebook/react/issues/15446#issuecomment-887701200
  useEffect(() => {
    if (imgRef.current && hasRendered) {
      imgRef.current.src = src || "";
    }
  }, [src, hasRendered]);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  let imageJSX: JSX.Element;

  //TODO: temporary solution to support both responsive types (image templates and hardcoded responsive config), accourding to requirenments in https://team-1626860771808.atlassian.net/browse/DIGIX-4692 hero banner might be displayed in the same way as in the retail site (so it might use templates). Rised to BA migration to image templates for a whole corporate
  if (imageTemplates && src) {
    const imageSrcSetWithTemplates = getImageSrcSetWithAmplienceTemplates(
      src,
      imageTemplates
    );
    imageJSX = (
      <StyledImage
        sizes={[
          `(max-width: ${theme.breakpoints.values.xs}) ${theme.breakpoints.values.xs}px`,
          `(max-width: ${theme.breakpoints.values.sm}) ${theme.breakpoints.values.sm}px`,
          `(max-width: ${theme.breakpoints.values.md}) ${theme.breakpoints.values.md}px`,
          `(max-width: ${theme.breakpoints.values.lg}) ${theme.breakpoints.values.lg}px`,
        ].join(",")}
        className={className}
        ref={imgRef}
        srcSet={imageSrcSetWithTemplates as string}
        src={src}
        alt={prepareTextAttribute(alt, true)}
        title={prepareTextAttribute(title)}
        onError={handleError}
        loading="lazy"
        {...props}
      />
    );
  } else if (responsiveImageConfig && src) {
    const imageSrcSet = getImageSrcSet(src, responsiveImageConfig);
    imageJSX = (
      <StyledImage
        sizes={[
          `(max-width: ${theme.breakpoints.values.xs}) ${theme.breakpoints.values.xs}px`,
          `(max-width: ${theme.breakpoints.values.sm}) ${theme.breakpoints.values.sm}px`,
          `(max-width: ${theme.breakpoints.values.md}) ${theme.breakpoints.values.md}px`,
          `(max-width: ${theme.breakpoints.values.lg}) ${theme.breakpoints.values.lg}px`,
        ].join(",")}
        className={className}
        ref={imgRef}
        src={src}
        srcSet={imageSrcSet}
        alt={prepareTextAttribute(alt, true)}
        title={prepareTextAttribute(title)}
        onError={handleError}
        loading="lazy"
        {...getTestAutomationProps("image")}
        {...props}
      />
    );
  } else {
    imageJSX = (
      <StyledImage
        src={src}
        loading="lazy"
        onError={handleError}
        className={className}
        title={prepareTextAttribute(title)}
        alt={prepareTextAttribute(alt, true)}
        {...getTestAutomationProps("image")}
        {...props}
      />
    );
  }

  const hasAriaDetails = props["aria-details"] && ariaDetailsDescription;
  return (
    <>
      {imageJSX}
      {hasAriaDetails ? (
        <div
          style={{ display: "none" }}
          id={props["aria-details"]}
          {...getTestAutomationProps("aria-details-desc")}
        >
          <DynamicMarkdown text={ariaDetailsDescription} />
        </div>
      ) : null}
    </>
  );
};
