import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Left32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M22 30L8 16L22 2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const Left24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M15 5L8 12L15 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};

export const LeftTiny24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M14 8L10 12L14 16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
