import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Leather32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M16 3H19.25C19.25 5.49 20.31 8 23 8C24.3283 8.06124 25.6493 7.77152 26.83 7.16L28 11C24.75 11.69 23.32 13.39 23.32 16.61C23.32 19.83 25.84 22.01 29 22.76L26.83 26.92C25 26 24.13 25.62 22.71 25.62C20.56 25.62 18.47 27.1 18.17 29H13.83C13.53 27.1 11.44 25.62 9.28999 25.62C7.86999 25.62 7 26 5.17 26.92L3 22.76C6.16 22.01 8.67999 19.76 8.67999 16.61C8.67999 13.46 7.25 11.69 4 11L5.17 7.16C6.35073 7.77152 7.67172 8.06124 9 8C11.69 8 12.75 5.49 12.75 3H16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
