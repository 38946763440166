import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const NotRanged24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M18.38 5.62C12.73 -0.0599999 2.81998 4.14 2.99998 12.12C3.03105 14.4655 3.97659 16.7061 5.63523 18.3647C7.29386 20.0234 9.53452 20.9689 11.88 21C19.87 21.18 24.06 11.27 18.38 5.62ZM15.38 6.62L6.37998 16.15C5.50113 14.9853 5.03971 13.5588 5.06998 12.1C5.08998 6.8 11.28 3.89 15.4 6.64L15.38 6.62ZM11.88 18.93C10.4205 18.965 8.99247 18.5031 7.82998 17.62L17.36 8.62C20.11 12.72 17.2 18.91 11.88 18.93Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
