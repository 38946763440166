import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const MasterCard = (props: IconProps) => (
  <BaseSVGIcon width="46" height="29" viewBox="0 0 46 29" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3948 24.7616C21.0116 26.8182 17.9203 28.0597 14.5423 28.0597C7.00499 28.0597 0.894775 21.8786 0.894775 14.2537C0.894775 6.6289 7.00499 0.447754 14.5423 0.447754C17.9203 0.447754 21.0116 1.68926 23.3948 3.74581C25.7779 1.68926 28.8693 0.447754 32.2472 0.447754C39.7846 0.447754 45.8948 6.6289 45.8948 14.2537C45.8948 21.8786 39.7846 28.0597 32.2472 28.0597C28.8693 28.0597 25.7779 26.8182 23.3948 24.7616Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3948 24.7616C26.3292 22.2294 28.1899 18.4614 28.1899 14.2537C28.1899 10.0461 26.3292 6.27808 23.3948 3.74581C25.7779 1.68926 28.8693 0.447754 32.2472 0.447754C39.7846 0.447754 45.8948 6.6289 45.8948 14.2537C45.8948 21.8786 39.7846 28.0597 32.2472 28.0597C28.8693 28.0597 25.7779 26.8182 23.3948 24.7616Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3948 3.74609C26.3292 6.27836 28.1898 10.0463 28.1898 14.254C28.1898 18.4616 26.3292 22.2295 23.3948 24.7618C20.4605 22.2295 18.5998 18.4616 18.5998 14.254C18.5998 10.0463 20.4605 6.27836 23.3948 3.74609Z"
      fill="#FF5E00"
    />
  </BaseSVGIcon>
);
