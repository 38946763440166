import { Theme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import { getFormatMedia } from "~/theme/utils/formatMedia";

import { COLOR_BLACK } from "./colors";

/*
 * Arial Black is not supported by iOS (https://developer.apple.com/fonts/system-fonts/)
 * and there is an open discussion of iOS randomly refusing to download custom fonts: https://developer.apple.com/forums/thread/671608
 * Also it is not listed on either big font provider service (Google Fonts, Adobe Fonts, etc.)
 * Arial-BoldMT added as a fallback for iOS to stay as close to design as possible
 */
export const ARIAL_BLACK_FONT_FAMILY = "'Arial Black', Arial-BoldMT, sans-serif";
export const DEFAULT_FONT_FAMILY = "Arial";

export enum FontWeights {
  FONT_WEIGHT_REGULAR = 400,
  FONT_WEIGHT_MEDIUM = 700,
  FONT_WEIGHT_BOLD = 900,
}

export enum TypographyBodyClass {
  PRICE = "Typography-price",
  ARTICLE_LARGE = "Typography-articleLarge",
  ARTICLE_LARGE_BOLD = "Typography-articleLargeBold",
  ARTICLE_LARGE_LINK = "Typography-articleLargeLink",
  ARTICLE_SMALL = "Typography-articleSmall",
  ARTICLE_SMALL_BOLD = "Typography-articleSmallBold",
  ARTICLE_SMALL_LINK = "Typography-articleSmallLink",
  ACCORDION_LARGE = "Typography-accordionLarge",
  ACCORDION_SMALL = "Typography-accordionSmall",
}

export enum TypographyCaptionClass {
  CATEGORY = "Typography-category",
  BACK_TO_TOP = "Typography-backToTop",
}

export enum TypographyButtonClass {
  MOBILE_LIGHT = "Typography-mobileLight",
  DESKTOP_BOLD = "Typography-desktopBold",
  DESKTOP_LIGHT = "Typography-desktopLight",
}

const STRONG_TAG_STYLE = {
  "& strong": {
    fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
  },
};

const EM_TAG_STYLE = {
  "& em": {
    fontStyle: "italic",
  },
};

export const getTypographyOptions = (theme: Theme): TypographyOptions => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);

  return {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeightRegular: FontWeights.FONT_WEIGHT_REGULAR,
    fontWeightMedium: FontWeights.FONT_WEIGHT_MEDIUM,
    fontWeightBold: FontWeights.FONT_WEIGHT_BOLD,

    allVariants: {
      fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
      textAlign: "left",
      fontStyle: "normal",
      overflowWrap: "anywhere",
    },
    h1: {
      fontSize: "80px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "80px",
      letterSpacing: "-0.005em",
      textTransform: "capitalize",
    },
    h1Regular: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "40px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "48px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "48px",
        lineHeight: "56px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "56px",
        lineHeight: "64px",
      },
    },
    h1Medium: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "24px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "32px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "28px",
        lineHeight: "36px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "40px",
        lineHeight: "48px",
      },
    },
    h1Small: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "18px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "24px",
      letterSpacing: "0.005em",
      textTransform: "capitalize",

      [BREAKPOINT_TABLET]: {
        fontSize: "20px",
        lineHeight: "28px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    h1ExtraSmall: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "18px",
      fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
      lineHeight: "24px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "20px",
        lineHeight: "28px",
      },
    },
    h2: {
      fontSize: "56px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "56px",
      letterSpacing: "0em",
      textTransform: "capitalize",
    },
    h3: {
      fontSize: "40px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "48px",
      letterSpacing: "0px",
      textTransform: "capitalize",
    },
    h4: {
      fontSize: "26px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "32px",
      letterSpacing: "0.01em",
      textTransform: "capitalize",
    },
    h5: {
      fontSize: "20px",
      fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
      lineHeight: "28px",
      letterSpacing: "0.01em",
      textTransform: "capitalize",
    },
    h6: {
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.01em",
    },
    subtitle1: {
      fontSize: "18px",
      FontWeight: FontWeights.FONT_WEIGHT_REGULAR,
      lineHeight: "24px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    button: {
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      fontWeight: FontWeights.FONT_WEIGHT_BOLD,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      textAlign: "center",

      [`&.${TypographyButtonClass.MOBILE_LIGHT}`]: {
        fontFamily: DEFAULT_FONT_FAMILY,
        fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
        textTransform: "uppercase",
        textAlign: "center",
      },

      [`&.${TypographyButtonClass.DESKTOP_LIGHT}`]: {
        fontFamily: DEFAULT_FONT_FAMILY,
        fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
        textTransform: "uppercase",
        textAlign: "center",
      },

      [`&.${TypographyButtonClass.DESKTOP_BOLD}`]: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        fontWeight: FontWeights.FONT_WEIGHT_BOLD,
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
        textTransform: "uppercase",
        textAlign: "center",
      },
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.02em",

      ...STRONG_TAG_STYLE,
      ...EM_TAG_STYLE,

      [`&.${TypographyBodyClass.PRICE}`]: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.03em",
        textTransform: "none",
      },

      [`&.${TypographyBodyClass.ARTICLE_LARGE}`]: {
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0.01em",
      },

      [`&.${TypographyBodyClass.ARTICLE_LARGE_BOLD}`]: {
        fontSize: "20px",
        fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
        lineHeight: "32px",
        letterSpacing: "0.01em",
      },

      [`&.${TypographyBodyClass.ARTICLE_LARGE_LINK}`]: {
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0.01em",
        textDecoration: "underline",
        textDecorationThickness: "1.5px",
        textUnderlineOffset: "2px",
      },

      [`&.${TypographyBodyClass.ARTICLE_SMALL}`]: {
        fontSize: "16px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
      },

      [`&.${TypographyBodyClass.ARTICLE_SMALL_BOLD}`]: {
        fontSize: "16px",
        fontWeight: FontWeights.FONT_WEIGHT_MEDIUM,
        lineHeight: "26px",
        letterSpacing: "0.02em",
      },

      [`&.${TypographyBodyClass.ARTICLE_SMALL_LINK}`]: {
        fontSize: "16px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
        textDecoration: "underline",
        textUnderlineOffset: "1px",
      },
      [`&.${TypographyBodyClass.ACCORDION_LARGE}`]: {
        fontSize: "26px",
        lineHeight: "32px",
        letterSpacing: "0.01em",
        fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
      },
      [`&.${TypographyBodyClass.ACCORDION_SMALL}`]: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.01em",
        fontWeight: FontWeights.FONT_WEIGHT_REGULAR,
      },
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.02em",

      ...STRONG_TAG_STYLE,
      ...EM_TAG_STYLE,
    },
    caption: {
      [`&.${TypographyCaptionClass.CATEGORY}`]: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
      },
      [`&.${TypographyCaptionClass.BACK_TO_TOP}`]: {
        fontSize: "12px",
        lineHeight: "12px",
        letterSpacing: "0.02em",
        color: COLOR_BLACK,
      },
    },
  };
};

// export const THEME_TYPOGRAPHY: TypographyOptions = ;

export type TextColor = "black" | "light";
