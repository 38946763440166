import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Question24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 4C13.5823 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93854 19.391C7.47673 18.7855 6.2273 17.7602 5.34825 16.4446C4.4692 15.129 4 13.5823 4 12C4 9.87827 4.84286 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
        fill="currentColor"
      />
      <path
        d="M9 10C9.00065 9.47111 9.14111 8.9518 9.40714 8.49469C9.67317 8.03758 10.0553 7.65892 10.5149 7.3971C10.9744 7.13527 11.495 6.99958 12.0238 7.00379C12.5527 7.00799 13.0711 7.15193 13.5264 7.42103C13.9817 7.69012 14.3578 8.07481 14.6165 8.53609C14.8752 8.99737 15.0074 9.51885 14.9997 10.0477C14.9919 10.5765 14.8445 11.0939 14.5724 11.5474C14.3002 12.0009 13.913 12.3744 13.45 12.63C12.8808 12.9258 12.4319 13.4101 12.18 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16C11.8022 16 11.6089 16.0586 11.4444 16.1685C11.28 16.2784 11.1518 16.4346 11.0761 16.6173C11.0004 16.8 10.9806 17.0011 11.0192 17.1951C11.0578 17.3891 11.153 17.5673 11.2929 17.7071C11.4327 17.847 11.6109 17.9422 11.8049 17.9808C11.9989 18.0194 12.2 17.9996 12.3827 17.9239C12.5654 17.8482 12.7216 17.72 12.8315 17.5556C12.9414 17.3911 13 17.1978 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
