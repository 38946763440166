import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Maestro = (props: IconProps) => (
  <BaseSVGIcon width="46" height="29" viewBox="0 0 46 29" fill="none" {...props}>
    <path
      d="M28.1899 14.2537C28.1899 21.8786 22.0796 28.0597 14.5423 28.0597C7.00499 28.0597 0.894775 21.8786 0.894775 14.2537C0.894775 6.6289 7.00499 0.447754 14.5423 0.447754C22.0796 0.447754 28.1899 6.6289 28.1899 14.2537Z"
      fill="#ED0006"
    />
    <path
      d="M45.8948 14.2537C45.8948 21.8786 39.7845 28.0597 32.2472 28.0597C24.7099 28.0597 18.5997 21.8786 18.5997 14.2537C18.5997 6.6289 24.7099 0.447754 32.2472 0.447754C39.7845 0.447754 45.8948 6.6289 45.8948 14.2537Z"
      fill="#0099DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3948 3.74609C26.3292 6.27836 28.1898 10.0463 28.1898 14.254C28.1898 18.4616 26.3292 22.2295 23.3948 24.7618C20.4605 22.2295 18.5998 18.4616 18.5998 14.254C18.5998 10.0463 20.4605 6.27836 23.3948 3.74609Z"
      fill="#6C6BBD"
    />
  </BaseSVGIcon>
);
