import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Edit24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M20.71 8.7158C20.8037 8.62284 20.8781 8.51223 20.9289 8.39038C20.9797 8.26852 21.0058 8.13781 21.0058 8.0058C21.0058 7.87379 20.9797 7.74308 20.9289 7.62122C20.8781 7.49936 20.8037 7.38876 20.71 7.2958L16.71 3.2958C16.6171 3.20207 16.5065 3.12768 16.3846 3.07691C16.2627 3.02614 16.132 3 16 3C15.868 3 15.7373 3.02614 15.6154 3.07691C15.4936 3.12768 15.383 3.20207 15.29 3.2958L3.29002 15.2958C3.19734 15.3892 3.12401 15.5001 3.07425 15.6219C3.02448 15.7437 2.99926 15.8742 3.00002 16.0058V20.0058C3.00002 20.271 3.10537 20.5254 3.29291 20.7129C3.48045 20.9004 3.7348 21.0058 4.00002 21.0058H8.00002C8.13162 21.0066 8.26209 20.9813 8.38392 20.9316C8.50576 20.8818 8.61658 20.8085 8.71002 20.7158L20.71 8.7158ZM16 5.4158L18.59 8.0058L17 9.5958L14.41 7.0058L16 5.4158ZM7.59002 19.0058H5.00002V16.4158L13 8.4158L15.59 11.0058L7.59002 19.0058Z"
        fill="currentColor"
      />
      <path
        d="M20 19.0058H13C12.7348 19.0058 12.4805 19.1112 12.2929 19.2987C12.1054 19.4862 12 19.7406 12 20.0058C12 20.271 12.1054 20.5254 12.2929 20.7129C12.4805 20.9004 12.7348 21.0058 13 21.0058H20C20.2652 21.0058 20.5196 20.9004 20.7071 20.7129C20.8947 20.5254 21 20.271 21 20.0058C21 19.7406 20.8947 19.4862 20.7071 19.2987C20.5196 19.1112 20.2652 19.0058 20 19.0058Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
