import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const SmallPointer16 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C8 15 13 11 13 7.15C13.0106 6.48335 12.8896 5.82115 12.6439 5.20133C12.3982 4.58151 12.0327 4.01623 11.5683 3.53786C11.1038 3.05949 10.5496 2.67743 9.93729 2.41354C9.325 2.14965 8.66667 2.00912 8 2C7.33332 2.00912 6.67499 2.14965 6.0627 2.41354C5.45041 2.67743 4.89618 3.05949 4.43174 3.53786C3.96731 4.01623 3.60179 4.58151 3.35611 5.20133C3.11043 5.82115 2.98941 6.48335 3 7.15C3 11 8 15 8 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.75C8.82843 8.75 9.5 8.07843 9.5 7.25C9.5 6.42157 8.82843 5.75 8 5.75C7.17157 5.75 6.5 6.42157 6.5 7.25C6.5 8.07843 7.17157 8.75 8 8.75Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
