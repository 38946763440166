import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const PriorityHigh24 = (props: IconProps) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="19" r="2" fill="currentColor" />
    <path d="M10 3h4v12h-4z" fill="currentColor" />
  </BaseSVGIcon>
);
