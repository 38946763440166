import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Tiktok24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.5337 9.70989C22.3375 9.7291 22.1408 9.73912 21.9436 9.73997C20.8798 9.74009 19.8327 9.47533 18.8969 8.96959C17.961 8.46384 17.1658 7.73303 16.583 6.8431V16.7084C16.583 20.7354 13.3185 24 9.29156 24C5.2646 24 2 20.7354 2 16.7084C2 12.6815 5.2646 9.41688 9.29156 9.41688C9.44369 9.41688 9.59233 9.43065 9.74208 9.43999V13.0334C9.59249 13.0155 9.44543 12.9881 9.29156 12.9881C7.23621 12.9881 5.57011 14.6542 5.57011 16.7095C5.57011 18.7649 7.23621 20.4308 9.29156 20.4308C11.3469 20.4308 13.1626 18.8113 13.1626 16.7556L13.1984 0H16.636C16.9602 3.08271 19.4458 5.49044 22.5371 5.71649V9.70973"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
