import { format } from "url";

import { ImageSource } from "~/bff/types/ImageSource";
import {
  DynamicImageSearchConfig,
  ImageTemplates,
  ResponsiveImageConfig,
} from "~/components/Image";
import { BreakpointsConfig, WindowFormat } from "~/hooks/useBreakpoints";
import { Nullable } from "~/types/utility.types";

export const getImageSrc = (image?: ImageSource): string =>
  format({
    protocol: "https:",
    host: image?.defaultHost,
    pathname: `/i/${encodeURIComponent(image?.endpoint ?? "")}/${encodeURIComponent(
      image?.name ?? ""
    )}`,
  });

export const toImageSource = (str: string): ImageSource => {
  // TODO: Rework this function to parse image src properly
  const match = str.match(/https:\/\/([\w\-.]+)\/i\/([\w-]+)\/(.+)/);
  return { defaultHost: match?.[1], endpoint: match?.[2], name: match?.[3] };
};

const responsiveImageWidthParams: BreakpointsConfig<string> = {
  [WindowFormat.MOBILE]: "560w",
  [WindowFormat.TABLET]: "1024w",
  [WindowFormat.DESKTOP]: "1920w",
  [WindowFormat.WIDE_DESKTOP]: "2048w",
};

const getImageSrcWithAmplienceConfig = (
  src: string,
  searchParams: DynamicImageSearchConfig
): string => {
  try {
    const { protocol, host, pathname } = new URL(src);

    return format({
      protocol,
      host,
      pathname,
      query: searchParams as Record<string, string>,
    });
  } catch {
    return "";
  }
};

export const getImageSrcSet = (
  src: string,
  responsiveConfig: ResponsiveImageConfig
): string => {
  return src.includes("https")
    ? [
        `${getImageSrcWithAmplienceConfig(
          src,
          responsiveConfig[WindowFormat.MOBILE]
        )} ${responsiveImageWidthParams[WindowFormat.MOBILE]}`,
        `${getImageSrcWithAmplienceConfig(
          src,
          responsiveConfig[WindowFormat.TABLET]
        )} ${responsiveImageWidthParams[WindowFormat.TABLET]}`,
        `${getImageSrcWithAmplienceConfig(
          src,
          responsiveConfig[WindowFormat.DESKTOP]
        )} ${responsiveImageWidthParams[WindowFormat.DESKTOP]}`,
        `${getImageSrcWithAmplienceConfig(
          src,
          responsiveConfig[WindowFormat.WIDE_DESKTOP]
        )} ${responsiveImageWidthParams[WindowFormat.WIDE_DESKTOP]}`,
      ].join(",")
    : "";
};

export const getResonsiveImageSrc = (
  src: string,
  responsiveConfig: ResponsiveImageConfig
): BreakpointsConfig<string> => {
  return {
    [WindowFormat.MOBILE]: getImageSrcWithAmplienceConfig(
      src,
      responsiveConfig[WindowFormat.MOBILE]
    ),
    [WindowFormat.TABLET]: getImageSrcWithAmplienceConfig(
      src,
      responsiveConfig[WindowFormat.TABLET]
    ),
    [WindowFormat.DESKTOP]: getImageSrcWithAmplienceConfig(
      src,
      responsiveConfig[WindowFormat.DESKTOP]
    ),
    [WindowFormat.WIDE_DESKTOP]: getImageSrcWithAmplienceConfig(
      src,
      responsiveConfig[WindowFormat.WIDE_DESKTOP]
    ),
  };
};

export const getImageSrcSetWithAmplienceTemplates = (
  src: string,
  templates: ImageTemplates
): Nullable<string> => {
  if (!templates) {
    return null;
  }
  return src?.includes("https")
    ? [
        `${src}?$${templates[WindowFormat.MOBILE]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.MOBILE] as string
        }`,
        `${src}?$${templates[WindowFormat.TABLET]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.TABLET]
        }`,
        `${src}?$${templates[WindowFormat.DESKTOP]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.DESKTOP]
        }`,
        `${src}?$${templates[WindowFormat.WIDE_DESKTOP]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.WIDE_DESKTOP]
        }`,
      ].join(",")
    : "";
};
