import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Fabric32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M5 16L3 14L5 12L3 10L5 8L3 6L6 3L8 5L10 3L12 5L14 3L16 5L18 3L20 5L22 3L24 5L26 3L29 6L27 8L29 10L27 12L29 14L27 16L29 18L27 20L29 22L27 24L29 26L26 29L24 27L22 29L20 27L18 29L16 27L14 29L12 27L10 29L8 27L6 29L3 26L5 24L3 22L5 20L3 18L5 16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
