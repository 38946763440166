import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const NoBleach24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 5L2.5 21H21.5L12 5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.5 21L2 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M2.5 21L21 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
