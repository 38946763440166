import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const ChemicalClean24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M2 2V22H22V2H2ZM10.9 12L3.5 19.4V4.6L10.9 12ZM4.6 3.5H19.5L12 10.9L4.6 3.5ZM12 13.1L19.4 20.5H4.6L12 13.1ZM13.1 12L20.5 4.6V19.5L13.1 12Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
