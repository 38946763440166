import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const UkFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
      <mask
        id="mask0"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="22"
        height="16"
      >
        <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect x="1" y="4" width="22" height="16" fill="#0A17A7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.00764823 2.46683L9.38096 8.91466V2.93332H14.6191V8.91466L24.0077 2.46683L25.1793 4.23544L17.7564 9.33332H23V14.6667H17.7564L25.1793 19.7645L24.0077 21.5331L14.6191 15.0853V21.0667H9.38096V15.0853L-0.00764835 21.5331L-1.17929 19.7645L6.24366 14.6667H1.00001V9.33332H6.24366L-1.17929 4.23544L-0.00764823 2.46683Z"
          fill="white"
        />
        <path
          d="M15.6677 9.06575L25.6191 2.39999"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          d="M16.7243 14.958L25.6452 21.0803"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          d="M7.29006 9.04836L-2.01517 2.66321"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          d="M8.29931 14.8839L-2.01517 21.8484"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 13.6H10.4286V20H13.5714V13.6H23V10.4H13.5714V4H10.4286V10.4H1V13.6Z"
          fill="#E6273E"
        />
      </g>
    </BaseSVGIcon>
  );
};
