import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Video16 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        d="M5.96398 2.19723L13.3757 6.86218C13.5663 6.98088 13.7239 7.14793 13.8332 7.34721C13.9426 7.5465 14 7.77131 14 7.99997C14 8.22864 13.9426 8.45345 13.8332 8.65273C13.7239 8.85202 13.5663 9.01906 13.3757 9.13777L5.96398 13.8027C5.76795 13.927 5.54296 13.9951 5.31252 13.9997C5.08208 14.0044 4.85464 13.9455 4.65397 13.8293C4.45329 13.713 4.28675 13.5436 4.17173 13.3388C4.0567 13.134 3.99743 12.9013 4.00009 12.6649V3.32364C3.99942 3.08827 4.06016 2.85699 4.17599 2.65379C4.29182 2.45058 4.45854 2.28284 4.65886 2.16794C4.85919 2.05304 5.08584 1.99517 5.31531 2.00032C5.54479 2.00547 5.76875 2.07345 5.96398 2.19723V2.19723Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
