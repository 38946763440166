import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Camera24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M16.07 13C16.07 12.2089 15.8354 11.4355 15.3959 10.7777C14.9564 10.1199 14.3316 9.60723 13.6007 9.30448C12.8698 9.00173 12.0656 8.92252 11.2897 9.07686C10.5137 9.2312 9.80099 9.61217 9.24158 10.1716C8.68217 10.731 8.30121 11.4437 8.14687 12.2196C7.99253 12.9956 8.07174 13.7998 8.37449 14.5307C8.67724 15.2616 9.18993 15.8864 9.84773 16.3259C10.5055 16.7654 11.2789 17 12.07 17C13.1309 17 14.1483 16.5786 14.8984 15.8284C15.6486 15.0783 16.07 14.0609 16.07 13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10C19 9.80222 18.9413 9.60888 18.8315 9.44443C18.7216 9.27998 18.5654 9.15181 18.3827 9.07612C18.2 9.00043 17.9989 8.98063 17.8049 9.01921C17.6109 9.0578 17.4327 9.15304 17.2929 9.29289C17.153 9.43275 17.0578 9.61093 17.0192 9.80491C16.9806 9.99889 17.0004 10.2 17.0761 10.3827C17.1518 10.5654 17.28 10.7216 17.4444 10.8315C17.6089 10.9414 17.8022 11 18 11C18.2652 11 18.5196 10.8946 18.7071 10.7071C18.8946 10.5196 19 10.2652 19 10Z"
        fill="currentColor"
      />
      <path
        d="M16.51 5.69L16.14 4.49C16.0082 4.06134 15.7433 3.6858 15.3837 3.41788C15.0241 3.14996 14.5884 3.00359 14.14 3H9.88C9.43155 3.00359 8.99592 3.14996 8.6363 3.41788C8.27667 3.6858 8.01176 4.06134 7.88 4.49L7.51 5.69C7.45331 5.91002 7.32339 6.10418 7.14162 6.2405C6.95986 6.37682 6.73709 6.44718 6.51 6.44H6.23C5.67367 6.44 5.12279 6.54974 4.60893 6.76294C4.09507 6.97615 3.62832 7.28862 3.2354 7.68247C2.84247 8.07632 2.5311 8.54382 2.31911 9.05818C2.10713 9.57254 1.99868 10.1237 2 10.68V15.68C1.99473 16.3751 2.12675 17.0643 2.38848 17.7082C2.65021 18.3522 3.03651 18.9381 3.52521 19.4323C4.01391 19.9266 4.5954 20.3195 5.2363 20.5885C5.87721 20.8576 6.56493 20.9974 7.26 21H16.74C18.1386 20.9947 19.478 20.4347 20.4641 19.4429C21.4503 18.4512 22.0027 17.1086 22 15.71V10.71C22.0027 9.58986 21.5609 8.5144 20.7717 7.71953C19.9824 6.92466 18.9101 6.47529 17.79 6.47H17.51C17.2788 6.47565 17.0529 6.40101 16.8705 6.2588C16.6882 6.11659 16.5608 5.91558 16.51 5.69Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
