import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Bullet24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="3" stroke="black" strokeWidth="2" />
    </BaseSVGIcon>
  );
};
