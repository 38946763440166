import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const FouxLeather32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M19 28C18.8175 27.3128 18.4079 26.7076 17.8378 26.2828C17.2677 25.8579 16.5707 25.6384 15.86 25.66C14.826 25.5779 13.8006 25.9005 13 26.56L11.5 23.68C13.69 23.16 15.44 21.6 15.44 19.42C15.44 17.24 14.5 15.51 12.25 15.04L13 12.88C13.5798 13.2982 14.2864 13.5031 15 13.46C15.887 13.4139 16.7226 13.0295 17.3347 12.3858C17.9467 11.7421 18.2886 10.8882 18.29 10H22.79C22.7799 10.8821 23.1062 11.735 23.7025 12.3852C24.2987 13.0353 25.1203 13.434 26 13.5C26.7136 13.5431 27.4202 13.3382 28 12.92L28.75 15.08C26.5 15.55 25.56 17.23 25.56 19.46C25.56 21.69 27.31 23.2 29.5 23.72L28 26.6C27.1994 25.9405 26.174 25.6178 25.14 25.7C24.4346 25.6762 23.7416 25.8899 23.1723 26.307C22.6029 26.7241 22.1901 27.3203 22 28H19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M14 2H12V12L17 20H10H3L8 12V2H6" fill="transparent" />
      <path
        d="M14 2H12V12L17 20H10H3L8 12V2H6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.84 17.05L10 17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6.84 14.05L10 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
