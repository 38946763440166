"use client";
import React from "react";

import { cx } from "@emotion/css";
import { capitalize, styled } from "@mui/material";

import { getTestAutomationProps } from "~/utils/testUtils";

const PREFIX = "StyledBaseSVGIcon";

const classes: Record<string, string> = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  colorSecondary: `${PREFIX}-colorSecondary`,
  colorAction: `${PREFIX}-colorAction`,
  colorError: `${PREFIX}-colorError`,
  colorDisabled: `${PREFIX}-colorDisabled`,
  colorSuccess: `${PREFIX}-colorSuccess`,
  colorWarning: `${PREFIX}-colorWarning`,
  fontSizeInherit: `${PREFIX}-fontSizeInherit`,
  fontSizeSmall: `${PREFIX}-fontSizeSmall`,
  fontSizeLarge: `${PREFIX}-fontSizeLarge`,
};

const StyledBaseSVGIcon = styled("svg")(({ theme }) => ({
  [`&.${classes.root}`]: {
    userSelect: "none",
    display: "inline-block",
    flexShrink: 0,
    transition: theme.transitions.create("fill", {
      duration: theme.transitions.duration.shorter,
    }),
  },

  /* Styles applied to the root element if `color="primary"`. */
  [`&.${classes.colorPrimary}`]: {
    color: theme.palette.primary.main,
  },

  /* Styles applied to the root element if `color="secondary"`. */
  [`&.${classes.colorSecondary}`]: {
    color: theme.palette.secondary.main,
  },

  /* Styles applied to the root element if `color="action"`. */
  [`&.${classes.colorAction}`]: {
    color: theme.palette.action.active,
  },

  /* Styles applied to the root element if `color="error"`. */
  [`&.${classes.colorError}`]: {
    color: theme.palette.error.main,
  },

  /* Styles applied to the root element if `color="disabled"`. */
  [`&.${classes.colorDisabled}`]: {
    color: theme.palette.action.disabled,
  },

  /* Styles applied to the root element if 'color="success". */
  [`&.${classes.colorSuccess}`]: {
    color: theme.palette.success.main,
  },

  /* Styles applied to the root element if 'color="warning". */
  [`&.${classes.colorWarning}`]: {
    color: theme.palette.warning.main,
  },

  /* Styles applied to the root element if `fontSize="inherit"`. */
  [`&.${classes.fontSizeInherit}`]: {
    fontSize: "inherit",
  },

  /* Styles applied to the root element if `fontSize="small"`. */
  [`&.${classes.fontSizeSmall}`]: {
    fontSize: theme.typography.pxToRem(20),
  },

  /* Styles applied to the root element if `fontSize="large"`. */
  [`&.${classes.fontSizeLarge}`]: {
    fontSize: theme.typography.pxToRem(35),
  },
}));

export {};

export interface BaseSVGIconProps extends React.SVGProps<SVGSVGElement> {
  color?:
    | "action"
    | "disabled"
    | "error"
    | "warning"
    | "inherit"
    | "primary"
    | "secondary"
    | "success";
  htmlColor?: string;
  fontSize?: "default" | "inherit" | "large" | "small";
  viewBox?: string;
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  title?: string;
}

export const BaseSVGIcon = ({
  children,
  color = "inherit",
  htmlColor,
  fontSize = "default",
  viewBox = "0 0 24 24",
  width,
  height,
  fill,
  stroke,
  ...passedProps
}: BaseSVGIconProps) => {
  return (
    <StyledBaseSVGIcon
      {...passedProps}
      className={cx(
        classes.root,
        {
          [classes[`color${capitalize(color)}`]]: color !== "inherit",
          [classes[`fontSize${capitalize(fontSize)}`]]: fontSize !== "default",
        },
        passedProps.className
      )}
      focusable="false"
      viewBox={viewBox}
      aria-hidden={passedProps.title ? undefined : true}
      role={undefined}
      width={width}
      height={height}
      fill={fill}
      color={htmlColor}
      stroke={stroke}
      {...getTestAutomationProps("icon")}
    >
      {children}
    </StyledBaseSVGIcon>
  );
};
