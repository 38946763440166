import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Trash = (props: IconProps) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M5.66797 6.10498V5.10498C5.39192 5.10498 5.12815 5.21909 4.93914 5.42028C4.75013 5.62147 4.6527 5.89185 4.66992 6.16736L5.66797 6.10498ZM19.668 6.10498L20.666 6.16736C20.6832 5.89185 20.5858 5.62147 20.3968 5.42028C20.2078 5.21909 19.944 5.10498 19.668 5.10498V6.10498ZM18.7852 20.2297L17.7871 20.1674L18.7852 20.2297ZM5.66797 7.10498H19.668V5.10498H5.66797V7.10498ZM18.6699 6.0426L17.7871 20.1674L19.7832 20.2921L20.666 6.16736L18.6699 6.0426ZM16.7891 21.105H8.54687V23.105H16.7891V21.105ZM7.54882 20.1674L6.66602 6.0426L4.66992 6.16736L5.55271 20.2921L7.54882 20.1674ZM8.54687 21.105C8.01881 21.105 7.58176 20.6944 7.54882 20.1674L5.55271 20.2921C5.65153 21.8732 6.96268 23.105 8.54687 23.105V21.105ZM17.7871 20.1674C17.7542 20.6944 17.3171 21.105 16.7891 21.105V23.105C18.3733 23.105 19.6844 21.8732 19.7832 20.2921L17.7871 20.1674Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.668 1.10498C10.4588 1.10498 8.66797 2.89584 8.66797 5.10498H4.66797C4.11568 5.10498 3.66797 5.5527 3.66797 6.10498C3.66797 6.65727 4.11568 7.10498 4.66797 7.10498H8.66797H16.668H20.668C21.2203 7.10498 21.668 6.65727 21.668 6.10498C21.668 5.5527 21.2203 5.10498 20.668 5.10498H16.668C16.668 2.89584 14.8771 1.10498 12.668 1.10498ZM14.668 5.10498C14.668 4.00041 13.7725 3.10498 12.668 3.10498C11.5634 3.10498 10.668 4.00041 10.668 5.10498H14.668Z"
      fill="black"
    />
    <rect x="9.66797" y="9.10498" width="2" height="10" rx="1" fill="black" />
    <rect x="13.668" y="9.10498" width="2" height="10" rx="1" fill="black" />
  </BaseSVGIcon>
);
