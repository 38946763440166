import { BreakpointsConfig, WindowFormat } from "~/hooks/useBreakpoints";

export type ResponsiveImageSrc = Record<WindowFormat, string>;

export type ResponsiveImageConfig = BreakpointsConfig<DynamicImageSearchConfig>;

export interface ImageProps<T> {
  alt?: string;
  title?: string;
  className?: string;
  src: T;
}

export enum AmplienceDynamicImageConfigKeys {
  WIDTH = "w",
  HEIGHT = "h",
  FORMAT = "fmt",
  CROP = "crop",
  EDGE_CROP = "ecrop",
  PRE_CROP = "pcrop",
  PRE_EDGE_CROP = "pecrop",
  ROTATE = "rotate",
}

export enum ImageFormatTypes {
  JPG = "jpg",
  PNG = "png",
  GIF = "gif",
  BMP = "bmp",
  WEBP = "webp",
  JP2 = "jp2",
}

export interface DynamicImageSearchConfig {
  [AmplienceDynamicImageConfigKeys.WIDTH]?: number;
  [AmplienceDynamicImageConfigKeys.HEIGHT]?: number;
  [AmplienceDynamicImageConfigKeys.FORMAT]?: ImageFormatTypes;
  [AmplienceDynamicImageConfigKeys.CROP]?: string;
  [AmplienceDynamicImageConfigKeys.EDGE_CROP]?: string;
  [AmplienceDynamicImageConfigKeys.PRE_CROP]?: string;
  [AmplienceDynamicImageConfigKeys.PRE_EDGE_CROP]?: string;
  [AmplienceDynamicImageConfigKeys.ROTATE]?: number;
}

export type ImageTemplates = {
  [key in WindowFormat]: string;
};
