import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Directions24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M22.43 10.59L13.42 1.57999C12.67 0.829989 11.35 0.819989 10.59 1.57999L1.59 10.58C0.810005 11.36 0.810005 12.62 1.59 13.4L10.59 22.4C10.98 22.79 11.49 22.98 12 22.98C12.51 22.98 13.02 22.79 13.41 22.4L22.4 13.41C23.19 12.65 23.2 11.39 22.43 10.59ZM12.01 20.99L3.01 11.99L12.01 2.98999L21.01 11.99L12.01 20.99ZM8 11V15H10V12H14V14.5L17.5 11L14 7.49999V9.99999H9C8.45 9.99999 8 10.45 8 11Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
