import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Pdf = (props: IconProps) => (
  <BaseSVGIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M7 8.00293V4.00293C7 3.47293 7.21 2.96293 7.59 2.59293C7.97 2.21293 8.47 2.00293 9 2.00293H19L23 6.00293V20.0029C23 20.5329 22.79 21.0429 22.41 21.4129C22.03 21.7929 21.53 22.0029 21 22.0029H9C8.47 22.0029 7.96 21.7929 7.59 21.4129C7.21 21.0329 7 20.5329 7 20.0029V19.0029"
      stroke="black"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M17 8.00293H3C1.89543 8.00293 1 8.89836 1 10.0029V17.0029C1 18.1075 1.89543 19.0029 3 19.0029H17C18.1046 19.0029 19 18.1075 19 17.0029V10.0029C19 8.89836 18.1046 8.00293 17 8.00293Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 16.0029V11.0029H5C5.83 11.0029 6.5 11.6729 6.5 12.5029C6.5 13.3329 5.83 14.0029 5 14.0029H4"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 14.0029H14"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 16.0029V11.0029H16"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 16.0029V11.0029H10C10.83 11.0029 11.5 11.6729 11.5 12.5029V14.5029C11.5 15.3329 10.83 16.0029 10 16.0029H9Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseSVGIcon>
);
