import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Facebook24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M24 12.0003C24 5.37218 18.6281 0.000305176 12 0.000305176C5.37188 0.000305176 0 5.37218 0 12.0003C0 17.9909 4.3875 22.955 10.125 23.855V15.4691H7.07812V12.0003H10.125V9.35656C10.125 6.34952 11.9156 4.68781 14.6578 4.68781C15.9703 4.68781 17.3438 4.92218 17.3438 4.92218V7.87531H15.8297C14.3391 7.87531 13.875 8.80109 13.875 9.75031V12.0003H17.2031L16.6711 15.4691H13.875V23.855C19.6125 22.955 24 17.9909 24 12.0003Z"
        fill="black"
      />
      <path
        d="M16.6711 15.4691L17.2031 12.0003H13.875V9.75031C13.875 8.80109 14.3391 7.87531 15.8297 7.87531H17.3438V4.92218C17.3438 4.92218 15.9703 4.68781 14.6578 4.68781C11.9156 4.68781 10.125 6.34952 10.125 9.35656V12.0003H7.07812V15.4691H10.125V23.855C10.7367 23.9511 11.3625 24.0003 12 24.0003C12.6375 24.0003 13.2633 23.9511 13.875 23.855V15.4691H16.6711Z"
        fill="white"
      />
    </BaseSVGIcon>
  );
};
