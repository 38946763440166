import { Theme } from "@mui/material";
import { Components, ComponentsProps } from "@mui/material/styles";

import {
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_15,
  COLOR_WHITE,
} from "~/theme/constants/colors";
import { getTestAutomationProps } from "~/utils/testUtils";

export enum TooltipType {
  DARK = "dark-tooltip",
  LIGHT = "light-tooltip",
}

export const getMuiTooltipComponent = (theme: Theme): Components["MuiTooltip"] => ({
  defaultProps: getTestAutomationProps("tooltip") as ComponentsProps["MuiTooltip"],
  styleOverrides: {
    tooltip: {
      borderRadius: 0,
      [`&.${TooltipType.LIGHT}`]: {
        backgroundColor: COLOR_WHITE,
        color: COLOR_BLACK,
        boxShadow: `0px ${theme.spacing(0.5)} ${theme.spacing(
          2
        )} ${COLOR_GREYSCALE_BLACK_15}`,
      },
      [`&.${TooltipType.DARK}`]: {
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        boxShadow: "none",
      },
    },
    arrow: {
      [`&.${TooltipType.LIGHT}`]: {
        "&::before": {
          color: theme.palette.common.white,
        },
      },
      [`&.${TooltipType.DARK}`]: {
        "&::before": {
          color: COLOR_BLACK,
        },
      },
    },
  },
});
