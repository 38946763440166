import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const LinkPic24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M6.27244 17.7276C5.06329 16.5184 5.06329 14.5527 6.27244 13.3435L8.42911 11.1868C8.80011 10.8158 8.80011 10.2143 8.42911 9.84333C8.05812 9.47233 7.45661 9.47233 7.08561 9.84333L4.92894 12C3.99125 12.9377 3.46447 14.2095 3.46447 15.5355C3.46447 16.8616 3.99125 18.1334 4.92894 19.0711C5.86662 20.0088 7.13839 20.5355 8.46447 20.5355C9.79055 20.5355 11.0623 20.0088 12 19.0711L14.1567 16.9144C14.5277 16.5434 14.5277 15.9419 14.1567 15.5709C13.7857 15.1999 13.1842 15.1999 12.8132 15.5709L10.6565 17.7276C9.44735 18.9367 7.48159 18.9367 6.27244 17.7276ZM9.17158 14.8284C9.5621 15.219 10.1953 15.219 10.5858 14.8284L14.8284 10.5858C15.219 10.1953 15.219 9.5621 14.8284 9.17158C14.4379 8.78105 13.8047 8.78105 13.4142 9.17158L9.17158 13.4142C8.78105 13.8047 8.78105 14.4379 9.17158 14.8284ZM12 4.92894L9.84333 7.08561C9.47233 7.45661 9.47233 8.05812 9.84333 8.42911C10.2143 8.80011 10.8158 8.80011 11.1868 8.42911L13.3435 6.27244C14.5527 5.06329 16.5184 5.06329 17.7276 6.27244C18.9367 7.48159 18.9367 9.44735 17.7276 10.6565L15.5709 12.8132C15.1999 13.1842 15.1999 13.7857 15.5709 14.1567C15.9419 14.5277 16.5434 14.5277 16.9144 14.1567L19.0711 12C20.0088 11.0623 20.5355 9.79055 20.5355 8.46447C20.5355 7.13839 20.0088 5.86662 19.0711 4.92894C18.1334 3.99125 16.8616 3.46447 15.5355 3.46447C14.2095 3.46447 12.9377 3.99125 12 4.92894Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
