import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const SiFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect
        x="1.25"
        y="4.25"
        width="21.5"
        height="15.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8.72142H5.19048V7.6738C5.19048 7.6738 5.9995 7.14999 6.7619 7.14999C7.52431 7.14999 8.26312 7.6738 8.26312 7.6738V8.72142H23V14H1V8.72142ZM7.80952 9.76904C7.80952 10.3476 7.34049 10.8167 6.7619 10.8167C6.18332 10.8167 5.71429 10.3476 5.71429 9.76904C5.71429 9.19046 6.18332 8.72142 6.7619 8.72142C7.34049 8.72142 7.80952 9.19046 7.80952 9.76904Z"
        fill="#0C47B7"
      />
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 20H23V14H1V20Z"
          fill="#E53B35"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="1"
          y="14"
          width="22"
          height="6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </BaseSVGIcon>
  );
};
