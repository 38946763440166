import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const ExploreProducts24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3707 3.76899C15.5265 3.6061 15.6075 3.37507 15.5703 3.1351C15.5067 2.72579 15.1234 2.44546 14.7141 2.50898C3.28164 4.28298 1.08852 15.8637 6.03809 21.0195C8.56659 23.6534 12.6316 23.8965 15.9621 22.5046C19.3219 21.1005 22.1416 17.9563 22.1416 13.5626C22.1416 10.1082 20.7263 7.64928 18.9486 6.00025C17.8088 4.94299 16.5238 4.22057 15.3707 3.76899Z"
        fill="#7FD6EC"
      />
      <path
        d="M11.4601 6.52897C11.7548 6.62721 11.9141 6.94577 11.8158 7.24048C11.623 7.81905 11.3367 9.01323 11.1651 10.5777C12.4624 10.6314 13.789 10.7931 14.7104 11.1781C14.997 11.2979 15.1323 11.6273 15.0126 11.914C14.8928 12.2006 14.5633 12.3359 14.2767 12.2162C13.532 11.905 12.3488 11.7502 11.0684 11.6998C11.0033 12.7312 10.9965 13.8756 11.0929 15.0802C11.1177 15.3899 10.8867 15.661 10.577 15.6858C10.2674 15.7106 9.99625 15.4796 9.97147 15.17C9.87233 13.9306 9.8771 12.7506 9.9424 11.6809C8.79087 11.6853 7.71026 11.7568 7.00537 11.8301C6.69638 11.8622 6.41985 11.6377 6.38772 11.3287C6.3556 11.0197 6.58005 10.7432 6.88904 10.7111C7.64662 10.6323 8.8059 10.5564 10.036 10.5557C10.2149 8.86144 10.5266 7.5506 10.7485 6.88473C10.8468 6.59001 11.1653 6.43073 11.4601 6.52897Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3238 1.76899C13.4797 1.6061 13.5606 1.37507 13.5234 1.1351C13.4599 0.725789 13.0766 0.445462 12.6672 0.508976C1.23477 2.28298 -0.958358 13.8637 3.99121 19.0195C6.51972 21.6534 10.5847 21.8965 13.9152 20.5046C17.2751 19.1005 20.0948 15.9563 20.0948 11.5626C20.0948 8.10815 18.6794 5.64928 16.9017 4.00025C15.7619 2.94299 14.4769 2.22057 13.3238 1.76899ZM10.8666 2.44592C2.38996 4.98081 1.30516 14.0556 5.07329 17.9807C7.04479 20.0343 10.3861 20.3537 13.3368 19.1206C16.2582 17.8997 18.5948 15.2314 18.5948 11.5626C18.5948 8.56706 17.3851 6.49468 15.8816 5.09996C14.3576 3.68626 12.5226 2.96414 11.3294 2.73686C11.1364 2.70009 10.9749 2.59241 10.8666 2.44592Z"
        fill="black"
      />
    </BaseSVGIcon>
  );
};
