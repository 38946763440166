import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Mic16 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <rect
        x="6"
        y="1"
        width="4"
        height="8"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M13 7C13 9.76142 10.7614 12 8 12C5.23858 12 3 9.76142 3 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 15H10M8 12V14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
