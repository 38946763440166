import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Error24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_21356_6113)">
        <path
          d="M13.0078 17C13.0078 16.4477 12.5601 16 12.0078 16C11.4555 16 11.0078 16.4477 11.0078 17C11.0078 17.5523 11.4555 18 12.0078 18C12.5601 18 13.0078 17.5523 13.0078 17Z"
          fill="currentColor"
        />
        <path
          d="M20.0086 22.0002H4.00855C3.30033 22.0065 2.60313 21.8247 1.98819 21.4733C1.37326 21.1219 0.862651 20.6136 0.508555 20.0002C0.157481 19.3921 -0.0273437 18.7023 -0.0273438 18.0002C-0.0273438 17.298 0.157481 16.6083 0.508555 16.0002L8.50855 2.10019C8.86895 1.49294 9.38114 0.989914 9.99478 0.640528C10.6084 0.291142 11.3024 0.107422 12.0086 0.107422C12.7147 0.107422 13.4087 0.291142 14.0223 0.640528C14.636 0.989914 15.1482 1.49294 15.5086 2.10019L23.5086 16.0002C23.8596 16.6083 24.0445 17.298 24.0445 18.0002C24.0445 18.7023 23.8596 19.3921 23.5086 20.0002C23.1545 20.6136 22.6438 21.1219 22.0289 21.4733C21.414 21.8247 20.7168 22.0065 20.0086 22.0002V22.0002ZM12.0086 2.10019C11.6511 2.09344 11.2983 2.18265 10.987 2.35852C10.6757 2.5344 10.4173 2.79051 10.2386 3.10019L2.27855 17.0002C2.10302 17.3042 2.01061 17.6491 2.01061 18.0002C2.01061 18.3513 2.10302 18.6962 2.27855 19.0002C2.45392 19.3039 2.70607 19.5562 3.00972 19.7317C3.31336 19.9072 3.65783 19.9998 4.00855 20.0002H20.0086C20.3644 20.0051 20.715 19.9151 21.0244 19.7393C21.3338 19.5635 21.5907 19.3084 21.7686 19.0002C21.9441 18.6962 22.0365 18.3513 22.0365 18.0002C22.0365 17.6491 21.9441 17.3042 21.7686 17.0002L13.7686 3.10019C13.5907 2.79201 13.3338 2.53687 13.0244 2.36108C12.715 2.1853 12.3644 2.09524 12.0086 2.10019V2.10019Z"
          fill="currentColor"
        />
        <path
          d="M12.8386 13.29L13.3486 8.49003C13.3698 8.30188 13.3509 8.11137 13.2931 7.93106C13.2353 7.75075 13.14 7.58474 13.0134 7.44396C12.8867 7.30317 12.7317 7.19082 12.5585 7.1143C12.3854 7.03778 12.1979 6.99884 12.0086 7.00003V7.00003C11.8192 6.99884 11.6318 7.03778 11.4586 7.1143C11.2854 7.19082 11.1304 7.30317 11.0038 7.44396C10.8772 7.58474 10.7819 7.75075 10.7241 7.93106C10.6663 8.11137 10.6474 8.30188 10.6686 8.49003L11.1786 13.29C11.2034 13.4906 11.3023 13.6746 11.4559 13.806C11.6095 13.9373 11.8066 14.0065 12.0086 14V14C12.2106 14.0065 12.4077 13.9373 12.5613 13.806C12.7148 13.6746 12.8137 13.4906 12.8386 13.29V13.29Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseSVGIcon>
  );
};
