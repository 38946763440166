import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const IeFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect
        x="1.25"
        y="4.25"
        width="21.5"
        height="15.5"
        rx="1.75"
        fill="white"
        stroke="#F5F5F5"
        strokeWidth="0.5"
      />
      <rect x="15.6667" y="4" width="7.33333" height="16" fill="#FF8515" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20H8.33333V4H1V20Z"
        fill="#07BB59"
      />
    </BaseSVGIcon>
  );
};
