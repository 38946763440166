import { UNKNOWN_LOCALE, DEFAULT_LOCALE, Locales } from "~/constants/i18n";
import { DELIVERY_KEY_MAPPERS } from "~/constants/routes";
import { Nullable } from "~/types/utility.types";

const VALID_LOCALES_LIST = Object.values(Locales)
  .filter((locales) => locales !== UNKNOWN_LOCALE)
  .join("|");

const SUPPORTED_LOCALES_LIST: string[] = Object.values(Locales);

export const convertDeliveryKey = (url: string): string => {
  const regExpString = `(([a-zA-Z]{2}-[a-zA-Z]{2})|${UNKNOWN_LOCALE})`;

  return DELIVERY_KEY_MAPPERS.reduce(
    (result, { from, to }): string =>
      result.replace(new RegExp(`${regExpString}${from}/`), `$1${to}/`),
    url
  ).replace("//", "/");
};

export const getPageLocale = (locale?: string): string => {
  return !locale || locale === UNKNOWN_LOCALE ? DEFAULT_LOCALE : locale;
};

export const isLocaleSupported = (locale?: string): boolean => {
  return SUPPORTED_LOCALES_LIST.includes(locale as string);
};

export const getLinkUrl = (locale: string, href = ""): string => {
  const isExternalLink = new RegExp("^http", "gi").test(href);

  if (isExternalLink) {
    return href;
  }

  const hasUnknownLocale = new RegExp(`^/?${UNKNOWN_LOCALE}/.*`).test(
    href?.toLowerCase()
  );

  if (hasUnknownLocale) {
    return href.replace(`/${UNKNOWN_LOCALE}`, `/${getPageLocale(locale)}`);
  }

  const doesHrefIncludesLocale = new RegExp(`^/?${VALID_LOCALES_LIST}/.*`).test(
    href?.toLowerCase()
  );

  const linkUrl = doesHrefIncludesLocale
    ? `/${href}`
    : `/${getPageLocale(locale)}/${href}`;

  return convertDeliveryKey(linkUrl);
};

export const getLocaleByCountryAndLanguage = (
  languageCode?: Nullable<string>,
  countryKey?: Nullable<string>
): string => {
  if (!languageCode || !countryKey) {
    return DEFAULT_LOCALE;
  }

  return `${languageCode}-${countryKey}`.toLowerCase();
};

export const getCountryLangFromLocale = (locale: string): string => {
  return locale.split("-")[0];
};
