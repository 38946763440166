import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const History24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M12.26 2.99997C11.0564 2.96519 9.85807 3.17227 8.73595 3.60896C7.61383 4.04566 6.59075 4.70308 5.72729 5.54229C4.86383 6.38151 4.17755 7.38546 3.70908 8.49469C3.24062 9.60392 2.9995 10.7959 3 12H0L3.89 15.89L4 16L8 12H5C4.99991 11.0722 5.18423 10.1538 5.54224 9.29789C5.90026 8.44202 6.42484 7.66587 7.08548 7.01453C7.74612 6.3632 8.52964 5.84968 9.3905 5.50384C10.2514 5.15799 11.1724 4.98672 12.1 4.99997C13.9148 5.04799 15.6421 5.79037 16.9259 7.0741C18.2096 8.35784 18.952 10.0851 19 11.9C19.0172 13.2162 18.6629 14.5106 17.9778 15.6346C17.2926 16.7586 16.3044 17.6667 15.1266 18.2545C13.9488 18.8424 12.6292 19.0862 11.3191 18.9581C10.009 18.8299 8.76154 18.3349 7.72 17.53C7.52913 17.379 7.28978 17.3029 7.04678 17.3158C6.80379 17.3287 6.57384 17.4297 6.4 17.6C6.29839 17.6994 6.21913 17.8193 6.16753 17.9517C6.11592 18.0842 6.09314 18.2261 6.10071 18.3681C6.10828 18.51 6.14603 18.6487 6.21143 18.7749C6.27683 18.9011 6.36839 19.0119 6.48 19.1C8.05442 20.3352 9.99884 21.0045 12 21C13.2041 21.0005 14.3961 20.7594 15.5053 20.2909C16.6145 19.8224 17.6185 19.1361 18.4577 18.2727C19.2969 17.4092 19.9543 16.3861 20.391 15.264C20.8277 14.1419 21.0348 12.9436 21 11.74C20.9088 9.45116 19.9587 7.28072 18.339 5.661C16.7193 4.04129 14.5488 3.09119 12.26 2.99997Z"
        fill="currentColor"
      />
      <path
        d="M14.83 13.41L13 11.59V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V12.41L13.41 14.83C13.503 14.9237 13.6136 14.9981 13.7354 15.0489C13.8573 15.0997 13.988 15.1258 14.12 15.1258C14.252 15.1258 14.3827 15.0997 14.5046 15.0489C14.6264 14.9981 14.737 14.9237 14.83 14.83C14.9237 14.737 14.9981 14.6264 15.0489 14.5046C15.0997 14.3827 15.1258 14.252 15.1258 14.12C15.1258 13.988 15.0997 13.8573 15.0489 13.7354C14.9981 13.6136 14.9237 13.503 14.83 13.41Z"
        fill="currentColor"
      />
    </BaseSVGIcon>
  );
};
