/* eslint no-process-env: 0 */

import { kebabCase } from "lodash";

export const TEST_AUTOMATION_ID = "data-testautomation-id";

export interface TestAutomationProps {
  [TEST_AUTOMATION_ID]?: string;
}

export const getTestAutomationProps = (
  key: string,
  locale?: string
): TestAutomationProps => {
  return {
    [TEST_AUTOMATION_ID]: kebabCase(key),
  };
};
