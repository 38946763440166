import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const EsFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8H23V4H1V8Z"
        fill="#DD172C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20H23V16H1V20Z"
        fill="#DD172C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 16H23V8H1V16Z"
        fill="#FFD133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76196 11.3333H7.80958V11.8571H6.76196V11.3333Z"
        fill="#FFEDB1"
      />
      <path
        d="M5.84474 11.5039C5.82855 11.3095 5.98191 11.1429 6.17693 11.1429H7.34691C7.54192 11.1429 7.69528 11.3095 7.67909 11.5039L7.56435 12.8807C7.52958 13.298 7.1807 13.619 6.76192 13.619C6.34313 13.619 5.99426 13.298 5.95948 12.8807L5.84474 11.5039Z"
        stroke="#A41517"
        strokeWidth="0.666667"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71423 11.8571H7.80947V12.381H7.28566L6.76185 13.4286L6.23804 12.381H5.71423V11.8571Z"
        fill="#A41517"
      />
      <rect
        x="4.14282"
        y="10.2857"
        width="1.04762"
        height="3.66667"
        rx="0.52381"
        fill="#A41517"
      />
      <rect
        x="8.33337"
        y="10.2857"
        width="1.04762"
        height="3.66667"
        rx="0.523809"
        fill="#A41517"
      />
      <path
        d="M5.71423 10.0762C5.71423 9.61333 6.08946 9.2381 6.55233 9.2381H6.97138C7.43424 9.2381 7.80947 9.61333 7.80947 10.0762C7.80947 10.1919 7.71566 10.2857 7.59995 10.2857H5.92376C5.80804 10.2857 5.71423 10.1919 5.71423 10.0762Z"
        fill="#A41517"
      />
    </BaseSVGIcon>
  );
};
