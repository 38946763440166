import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const Minus24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M19 12L5 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
