import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const PtFlag24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
      <rect x="1" y="4" width="22" height="16" fill="#FF2936" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20H9.38095V4H1V20Z"
        fill="#128415"
      />
      <path
        d="M9.381 14.8571C10.9064 14.8571 12.1429 13.6206 12.1429 12.0952C12.1429 10.5699 10.9064 9.33333 9.381 9.33333C7.85565 9.33333 6.6191 10.5699 6.6191 12.0952C6.6191 13.6206 7.85565 14.8571 9.381 14.8571Z"
        stroke="#FAF94F"
        strokeWidth="1.33333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33337 10.8571C8.33337 10.673 8.48261 10.5238 8.66671 10.5238H10.0953C10.2794 10.5238 10.4286 10.673 10.4286 10.8571V12.619C10.4286 13.1976 9.95958 13.6667 9.38099 13.6667C8.80241 13.6667 8.33337 13.1976 8.33337 12.619V10.8571Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38099 12.6191C9.67028 12.6191 9.9048 11.8607 9.9048 11.5714C9.9048 11.2821 9.67028 11.0476 9.38099 11.0476C9.0917 11.0476 8.85718 11.2821 8.85718 11.5714C8.85718 11.8607 9.0917 12.6191 9.38099 12.6191Z"
        fill="#1D50B5"
      />
    </BaseSVGIcon>
  );
};
