import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const CloseTiny24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M9 9L15 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9 15L15 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </BaseSVGIcon>
  );
};
