import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const SyntheticFabric32 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <path
        d="M13 20L11 18L13 16L11 14L14 11L16 13L18 11L20 13L22 11L24 13L26 11L29 14L27 16L29 18L27 20L29 22L27 24L29 26L26 29L24 27L22 29L20 27L18 29L16 27L14 29L11 26L13 24L11 22L13 20Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M14 2H12V12L17 20H10H3L8 12V2H6" fill="transparent" />
      <path
        d="M14 2H12V12L17 20H10H3L8 12V2H6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.84 17.05L10 17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6.84 14.05L10 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
