import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const GooglePay = (props: IconProps) => (
  <BaseSVGIcon width="50" height="21" viewBox="0 0 50 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0603 16.5159V10.474H26.1786C27.4564 10.474 28.535 10.0459 29.4142 9.20142L29.6252 8.98733C31.2313 7.23899 31.1258 4.51537 29.4142 2.89785C28.5584 2.04152 27.3861 1.57767 26.1786 1.60146H21.1729V16.5159H23.0603ZM23.0605 8.6424V3.43303H26.2262C26.9063 3.43303 27.5512 3.69469 28.0319 4.17043C29.052 5.16949 29.0754 6.83458 28.0905 7.86932C27.6098 8.38074 26.9297 8.66618 26.2262 8.6424H23.0605ZM38.4293 7.10817C37.6204 6.35888 36.5185 5.97829 35.1234 5.97829C33.3298 5.97829 31.9816 6.64432 31.0906 7.9645L32.7553 9.02303C33.3649 8.11912 34.1973 7.66717 35.2524 7.66717C35.9206 7.66717 36.5653 7.91693 37.0694 8.36888C37.5618 8.79705 37.8432 9.41551 37.8432 10.0697V10.5097C37.1163 10.1053 36.2019 9.89125 35.0765 9.89125C33.7635 9.89125 32.7084 10.2005 31.923 10.8308C31.1375 11.4612 30.739 12.2937 30.739 13.3523C30.7155 14.3156 31.1258 15.2314 31.8527 15.8499C32.5912 16.5159 33.5291 16.849 34.631 16.849C35.9323 16.849 36.9639 16.2662 37.7494 15.1006H37.8314V16.5159H39.6368V10.2243C39.6368 8.90409 39.2382 7.85746 38.4293 7.10817ZM33.3066 14.6368C32.9197 14.3513 32.6852 13.8875 32.6852 13.3879C32.6852 12.8289 32.9432 12.3651 33.4473 11.9964C33.9632 11.6277 34.6081 11.4374 35.3702 11.4374C36.4255 11.4255 37.2462 11.6634 37.8325 12.1391C37.8325 12.9479 37.5159 13.6496 36.8945 14.2443C36.3317 14.8152 35.5696 15.1363 34.7723 15.1363C34.2446 15.1482 33.7287 14.9698 33.3066 14.6368ZM43.693 20.9998L50.0001 6.31131H47.9485L45.0295 13.6377H44.9943L42.0049 6.31131H39.9534L44.0916 15.8618L41.747 20.9998H43.693Z"
      fill="#3C4043"
    />
    <path
      d="M16.5442 9.16565C16.5442 8.58287 16.4973 8.00009 16.4035 7.4292H8.44354V10.7237H13.0038C12.8163 11.7822 12.2067 12.7337 11.3157 13.3284V15.4692H14.0355C15.6298 13.9825 16.5442 11.7822 16.5442 9.16565Z"
      fill="#4285F4"
    />
    <path
      d="M8.44391 17.5387C10.7182 17.5387 12.6408 16.7775 14.0358 15.4693L11.3161 13.3284C10.5541 13.8517 9.58105 14.1491 8.44391 14.1491C6.23996 14.1491 4.37599 12.6386 3.70777 10.6167H0.905945V12.8289C2.33616 15.719 5.25522 17.5387 8.44391 17.5387Z"
      fill="#34A853"
    />
    <path
      d="M3.70803 10.6167C3.35628 9.55818 3.35628 8.40451 3.70803 7.33409V5.13379H0.905755C-0.301918 7.54817 -0.301918 10.4026 0.905755 12.817L3.70803 10.6167Z"
      fill="#FBBC04"
    />
    <path
      d="M8.44391 3.80179C9.65139 3.77801 10.812 4.24185 11.6795 5.08629L14.0944 2.63623C12.5587 1.18522 10.5423 0.388352 8.44391 0.412139C5.25522 0.412139 2.33616 2.24374 0.905945 5.13387L3.70777 7.34606C4.37599 5.31227 6.23996 3.80179 8.44391 3.80179Z"
      fill="#EA4335"
    />
  </BaseSVGIcon>
);
