import React from "react";

import { IconProps } from "../icons.types";

import { BaseSVGIcon } from "./BaseSVGIcon";

export const LocationSelected24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M15.81 20H15.61C17.73 18.31 21 14.43 21 10C21 8.14348 20.2625 6.36301 18.9497 5.05025C17.637 3.7375 15.8565 3 14 3C12.1435 3 10.363 3.7375 9.05025 5.05025C7.7375 6.36301 7 8.14348 7 10C7 14.43 10.27 18.31 12.39 20H12.15C8.07 20.19 5 21 5 22C5 23 9 24 14 24C19 24 23 23.1 23 22C23 20.9 19.91 20.23 15.81 20ZM11 10C11 9.40666 11.1759 8.82664 11.5056 8.33329C11.8352 7.83994 12.3038 7.45542 12.852 7.22836C13.4001 7.0013 14.0033 6.94189 14.5853 7.05764C15.1672 7.1734 15.7018 7.45912 16.1213 7.87868C16.5409 8.29824 16.8266 8.83279 16.9424 9.41473C17.0581 9.99667 16.9987 10.5999 16.7716 11.1481C16.5446 11.6962 16.1601 12.1648 15.6667 12.4944C15.1734 12.8241 14.5933 13 14 13C13.2044 13 12.4413 12.6839 11.8787 12.1213C11.3161 11.5587 11 10.7956 11 10Z"
        fill="#7FD6EC"
      />
      <path
        d="M19 8C19 14 13 19 12 19C11 19 5 14 5 8C5 6.14348 5.7375 4.36301 7.05025 3.05025C8.36301 1.7375 10.1435 1 12 1C13.8565 1 15.637 1.7375 16.9497 3.05025C18.2625 4.36301 19 6.14348 19 8Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.81 18C17.91 18.19 21 19 21 20C21 21 17 22 12 22C7 22 3 21.1 3 20C3 18.9 6.07 18.23 10.15 18"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};

export const Location24 = (props: IconProps) => {
  return (
    <BaseSVGIcon width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M19 8C19 14 13 19 12 19C11 19 5 14 5 8C5 6.14348 5.7375 4.36301 7.05025 3.05025C8.36301 1.7375 10.1435 1 12 1C13.8565 1 15.637 1.7375 16.9497 3.05025C18.2625 4.36301 19 6.14348 19 8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.81 18C17.91 18.19 21 19 21 20C21 21 17 22 12 22C7 22 3 21.1 3 20C3 18.9 6.07 18.23 10.15 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </BaseSVGIcon>
  );
};
